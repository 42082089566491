.page-list {
    display: block;
    flex: 1 0 100%;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.page-item {
    flex: 1 0 100%;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    display: none;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;

    &.active,
    &.swiping {
        display: block;
    }

    &.previous {
        margin-left: -100%;
    }

    &.next {
        margin-left: 100%;
    }
}

.splash-page {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.card-page {
    background-color: #eee;
}

.control-bar-container {
    margin-top: 1rem;
}

.details-container {
    display: flex;

    .details-left,
    .details-right {
        width: 50%;
    }

    .details-left {
        margin-right: 2em;
    }
}


@media only screen and (max-width: $mobile-breakpoint) {
    .details-container {
        flex-direction: column;

        .details-left,
        .details-right {
            width: 100%;
        }

        .details-left {
            margin-right: 0 !important;
        }
    }
}
