.dropdown-component {
    position: relative;

    &.active {
        .dropdown-icon {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .dropdown-icon {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 24px;
    }

    .menu {
        @include z-depth-1;
        right: 0;
        top: -2px;
    }

    &.apps-dropdown, &.user-account-dropdown {
        .menu {
            padding: 0 !important;
            top: 60px;
        }
    }

    &.apps-dropdown {
        .menu {
            right: -57px;
        }
    }

    &.user-account-dropdown {
        .menu {
            right: 0;
        }
    }
}
