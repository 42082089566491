
.action-icon {

}
// in modal tips, this gets extracted out of .action-icon context
.action-button {
    .badge {
        position: absolute;
        top: 4px;
        right: 4px;
        box-shadow: none;
    }
}
