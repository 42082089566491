/*
    Material Design component
*/
/*
    SG stuff
*/

.menu-component,
.menu {
    position: absolute;

    ul {
        list-style: none;

        display: block;
        box-sizing: border-box;
        min-width: 3*$units;
        padding: 0;
        margin: 0;
        border-radius: 2px;

        font-size: 1rem;
        text-align: left;

        @include z-depth-3;
        @include bg-style(#fff, $body-color);

        li {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;
            cursor: pointer;

            a {
                color: $body-color;
            }
        }
    }
}

@mixin menu-item {
    list-style: none;
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 48px;
    padding: 0 16px;
    margin: 0;
    line-height: 48px;

    color: inherit !important;
    text-decoration: none !important;
    @include text-overflow-ellipsis;
}
@mixin menu-item-hover {
    background-color: #efefef;
    text-decoration: none !important;
}
@mixin menu-item-first {
    border-radius: 2px 2px 0 0;
}
@mixin menu-item-last {
    border-radius: 0 0 2px 2px;
}

.menu-item > a,
.menu-item > .menu-action {
    @include menu-item;
}
.menu-item-hover > a,
.menu-item-hover > .menu-action {
    @include menu-item-hover;
}
.menu-item-first > a,
.menu-item-first > .menu-action {
    @include menu-item-first;
}
.menu-item-last > a,
.menu-item-last > .menu-action {
    @include menu-item-last;
}

.menu > ul > * > a,
.menu > ul > * > .menu-action,
.menu-group > ul > * > a,
.menu-group > ul > * > .menu-action {
    @include menu-item;
}
.menu-item:hover > a,
.menu-item:focus > a,
.menu-item:hover > .menu-action,
.menu-item:focus > .menu-action,
.menu > ul > *:hover > a,
.menu > ul > *:hover > .menu-action,
.menu > ul > *:focus > a,
.menu > ul > *:focus > .menu-action,
.menu-group > ul > *:hover > a,
.menu-group > ul > *:focus > a,
.menu-group > ul > *:hover > .menu-action,
.menu-group > ul > *:focus > .menu-action,
.menu .selected > a,
.menu .selected > .menu-action {
    @include menu-item-hover;
}

.menu-item:first-child > a,
.menu-item:first-child > .menu-action,
.menu > ul > *:first-child > a,
.menu > ul > *:first-child > .menu-action {
    @include menu-item-first;
}

.menu-item:last-child > a,
.menu-item:last-child > .menu-action,
.menu > ul > *:last-child > a,
.menu > ul > *:last-child > .menu-action,
.menu > .menu-group:last-child > ul > *:last-child > a,
.menu > .menu-group:last-child > ul > *:last-child > .menu-action {
    @include menu-item-last;
}

.menu > ul > .divider,
.menu-group > ul > .divider {
    @include divider;
    background-color: transparent;
    border-radius: 0;
}

.menu-group-label {
    color: #ccc;
    text-transform: uppercase;
    background-color: transparent !important;
}
.menu-group > ul {
    padding: 0 !important;
}
.menu-group > ul > *  > a {
    padding: 0 32px;
}


.menu-item-checkbox {
    position: absolute;
    top: 14px;
    left: 16px;

    display: block;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 2px;
    transition: 0.3s all ease-in-out;
}
.checked > .menu-item-checkbox {
    background-color: $checked-color;
    border-color: $checked-color;
}
.menu-item-checkbox:after {
    content: "";
    position: absolute;
    left: 4.66667px;
    top: .22222px;
    box-sizing: border-box;
    transform: rotate(45deg);
    display: table;
    width: 6.66667px;
    height: 13.33333px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    border-color: transparent;
    transition: 0.3s all ease-in-out;
}
.checked > .menu-item-checkbox:after {
    border-color: rgba(255,255,255,0.87);
}



.checkbox-menu > ul {
    max-height: 240px;
    overflow-y: auto;
}
.checkbox-menu > ul > *,
.checkbox-menu > ul > .menu-group > * {
    padding-left: 48px;
}
/* special create new option does not have a checkbox so we don't need as much padding */
.checkbox-menu > ul > .menu-item-create {
    padding-left: 16px;
}

.modal-menu {
    z-index: 101;
}
.menu-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    width: auto;
    height: auto;
}


.more-menu {
    top: 8px;
    right: 8px;
    width: 3*$units;
}
.more-menu-icon {
    @include icon-wrapper;
}


.menu .icon {
    vertical-align: middle;
    margin-right: 8px;
    color: rgba(0,0,0,0.6);
}




/*
    React component

    TODO: not sure if this menu is the same thing as the
    material design component above...
*/
.navigation-menu {
    list-style: none;
    margin: 8px 0;
    padding: 0;

    li {
        display: block;

        &.divider {
            margin: 8px 0;
        }

        &.selected {
            a {
                color: #e44c00 !important;
            }
        }

        a {
            color: rgba(0, 0, 0, 0.87) !important;
            display: block;
            height: 48px;
            line-height: 48px;
            padding: 0 16px;
            position: relative;
            text-decoration: none !important;

            .icon {
                fill: rgba(0, 0, 0, 0.54);
                margin: -2px 26px 0 0;
                vertical-align: middle;
            }
        }
    }
}
