//
//
//
//
//#reports-filters-form {
//    padding: 8px 16px 12px 16px;
//    color: #aaa;
//    background-color: #fff;
//    @include z-depth-1-half;
//}
//#reports-date-range {
//    align-items: center;
//}
//#reports-date-range .text-field {
//    position: relative;
//    display: inline-block;
//}
//
//// fix for positioning bug with flatpickr
//.flatpickr-calendar.rightMost {
//    right: auto !important;
//}
//
//input.reports_flatpickr {
//    width: auto;
//    max-width: 130px;
//    border-radius: 0 !important;
//}
//
//
//
//
//.ct-chart {
//    max-width: 480px;
//}
//.ct-label {
//    fill: #fff;
//    color: #fff;
//    cursor: pointer;
//}
//
//
//
//.ct-slice-donut {
//    transition: all 0.3s ease-in-out;
//    opacity: 0.4;
//    stroke-width: 64px;
//    cursor: pointer;
//}
//.ct-slice-donut.ct-selected {
//    opacity: 1;
//    stroke-width: 72px !important;
//}
//.ct-series-empty .ct-slice-donut {
//    cursor: default;
//}
//
//
//
//
//
//
//.chart-list-item {
//    position: relative;
//    display: flex;
//    flex-flow: row nowrap;
//    justify-content: center;
//    align-items: center;
//
//    box-sizing: border-box;
//    height: auto;
//    padding: 0 0 0 24px;
//
//    background-color: #fff;
//    opacity: 1;
//
//}
//.chart-list-item > * {
//    padding-top: 8px;
//    padding-bottom: 8px;
//}
//.chart-list-item .list-item-main {
//    font-size: $list-item-font-size;
//    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
//}
//.chart-list-item .list-item-main {
//    color: inherit !important;
//    text-decoration: none !important;
//}
//
//
//.chart-list-item:last-child .list-item-main {
//    border-bottom: 0;
//}
//
//
//
//
//.ct-key-item {
//    padding: 0;
//    margin-bottom: 1px;
//    cursor: pointer;
//}
//.ct-key-label {
//    display: inline-block;
//    border: 0;
//    border-left-width: 4px;
//    border-style: solid;
//
//    height: $ct-key-item-height;
//    padding: 0 12px;
//    line-height: $ct-key-item-height;
//    vertical-align: middle;
//
//    opacity: 0.6;
//}
//.ct-key-value,
//.ct-key-percent {
//    display: inline-block;
//    height: $ct-key-item-height;
//    line-height: $ct-key-item-height+2;
//    vertical-align: middle;
//    @include small;
//}
//.ct-key-value {
//    margin-right: 4px;
//    color: #666;
//    font-weight: bold;
//}
//.ct-selected .ct-key-label {
//    opacity: 1;
//    border-left-width: 12px;
//}
//
//
//
//
//
//.reports-loading {
//    display: none;
//    padding: 16px;
//    text-align: center;
//}
//.results-loading .reports-loading {
//    display: block;
//}

//New Stuff

.reports-page {
    .control-bar-component {
        > div {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
        }

        .form-field-component {
            //width: 200px;

            .form-error {
                display: none;
            }
        }

        .v-divider {
            text-align: center;
            width: 20px;
        }
    }

    .reports-chart-item {
        .list-item-footer-component {
            margin: 0;
            padding: 20px 20px 20px 0;
        }
    }
}

   
      
      
