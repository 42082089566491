
.ih-snackbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -48px;
    z-index: 50;
    box-sizing: border-box;
    height: 48px;
    padding: 14px 24px;

    font-size: 14px;
    color: #fff;
    background-color: #323232;

    transition: all 0.3s ease-in-out;
}

.with-snackbar .ih-snackbar {
    bottom: 0;
}


.ih-snackbar-message {

}
