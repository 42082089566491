


// dirty fix
.bg-color-dark .bg-color-light a {
  color: $body-color;
}

.bg-color-body { @include bg-style($body-bg, $body-color); }
.bg-color-page { @include bg-style($page-bg, $body-color); }

.bg-color-light { @include bg-style($white, $body-color); }
.bg-color-white { @include bg-style(#fff, $body-color); }

.bg-color-gray { @include bg-style($gray-light, #fff); }
.bg-color-dark { @include bg-style($dark, #fff); }
.bg-color-primary { @include bg-style($brand-primary, #fff); }
.bg-color-success { @include bg-style($brand-success, #fff); }
.bg-color-info { @include bg-style($brand-info, #fff); }
.bg-color-info-alt { @include bg-style($brand-info-alt, #fff); }
.bg-color-warning { @include bg-style($brand-warning, $body-color); }
.bg-color-danger { @include bg-style($brand-danger, #fff); }



// for mdl-data-table
.mdl-data-table tbody {
  .bg-color-dark { @include bg-style($dark, #fff); }
  .bg-color-primary { @include bg-style($brand-primary, #fff); }
  .bg-color-success { @include bg-style($brand-success, #fff); }
  .bg-color-info { @include bg-style($brand-info, #fff); }
  .bg-color-danger { @include bg-style($brand-danger, #fff); }
}
