
.touch-indicator {
    position: absolute;

    // to be set dynamically and animated.
    top: 50%;
    left: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 4px;
    height: 4px;
    margin: -2px 0 0 -2px;

    z-index: 999;
}

.touch-indicator:before {
    content: '';
    position: absolute;

    display: block;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: fade($color-touch-indicator, 6%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.touch-indicator.open:before {
    width: 160px;
    height: 160px;
    opacity: 1;
}
.touch-indicator:after {
    content: '';
    position: absolute;
    display: block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: fade($color-touch-indicator, 15%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.touch-indicator.open:after {
    width: 66px;
    height: 66px;
    opacity: 1;
}
.touch-indicator > span {
    position: absolute;
    width: 52px;
    height: 52px;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-color: $color-touch-indicator;
    border-radius: 50%;
    background-color: fade($color-touch-indicator, 50%);
//    opacity: 0.5;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.touch-indicator.open > span {
    width: 48px;
    height: 48px;
    border-width: 3px;
    opacity: 1;
}

/* New Stuff */

.touch-indicator-component {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    span {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
    }

    > span {
        background-color: fade($color-touch-indicator, 6%);
        height: 66px;
        margin-left: 150px;
        opacity: 0;
        transition: 500ms ease-in-out;
        width: 66px;

        > span {
            background-color: fade($color-touch-indicator, 15%);
            height: 52px;
            transition: 300ms ease-in-out, margin 500ms ease-in-out;
            width: 52px;

            > span {
                background-color: fade($color-touch-indicator, 50%);
                border-color: $color-touch-indicator;
                border-style: solid;
                border-width: 1px;
                box-sizing: border-box;
                height: 52px;
                transition: 300ms ease-in-out, margin 500ms ease-in-out;
                width: 52px;
            }
        }
    }

    &.open-enter {
        > span {
            height: 66px;
            margin-left: 150px;
            opacity: 0.01;
            width: 66px;

            > span {
                height: 52px;
                width: 52px;

                > span {
                    border-width: 1px;
                    height: 52px;
                    width: 52px;
                }
            }
        }
    }

    &.open-exit {
        > span {
            height: 160px;
            margin-left: -150px;
            opacity: 1;
            width: 160px;

            > span {
                height: 66px;
                width: 66px;

                > span {
                    border-width: 3px;
                    height: 48px;
                    width: 48px;
                }
            }
        }
    }
}
