.btn {
    background-color: #00acfd;
    border: 0;
    border-radius: 2px;
    box-sizing: border-box;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    outline: none;
    overflow: visible;
    padding: 0 24px;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    white-space: nowrap;

    &.btn-tab {
        margin: 0 2px;
        text-transform: capitalize;
        cursor: pointer;
        padding: 0 8px;
        color: #aaa !important;
        height: 28px;
        line-height: 28px;

        @media only screen and (max-width: $bp-gt-phone-portrait) {
            font-size: 12px;
        }

        &:hover {
            color: rgb(41, 43, 48) !important;
        }

        &.filtered {
            background-color: #C8C8C8;
            color: rgb(41, 43, 44) !important;
        }
    }

    &.btn-transparent {
        background-color: transparent;
        text-shadow: none;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }

    &.btn-white {
        background-color: #fff;
        text-shadow: none;

        &:focus {
            box-shadow: none;
        }
    }

    &.btn-gray {
        background-color: #999;
    }

    &.btn-red {
        background-color: #ed3d2d;
    }

    &.btn-text-red {
        color: #ed3d2d !important;
    }

    &.btn-text-blue {
        color: #2196f3 !important;
    }

    &.btn-text-green {
        color: #26a654 !important;
    }

    &.btn-text-black {
        color: #222 !important;
    }

    &.bstrp-btn {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        text-transform: none;
        border-radius: 0.25rem;
        line-height: 1.25;
        white-space: nowrap;
        text-shadow: none;
        height: auto;

        &:focus {
            box-shadow: none;
            outline: none;
        }

        &.btn-primary {
            background-color: #0275d8 !important;
            border-color: #0275d8 !important;
            color: #fff !important;
        }

        &.btn-link {
            color: #0275d8 !important;
            background-color: transparent;
            border-color: transparent;
        }
    }

    &[disabled] {
        cursor: not-allowed !important;
        opacity: 0.5;
    }

    &[hidden] {
        visibility: hidden;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &.floating {
        @include z-depth-5;

        align-items: center;
        border-radius: 50%;
        bottom: 16px;
        display: flex;
        flex-flow: row nowrap;
        height: 56px;
        justify-content: center;
        line-height: 1;
        padding: 0;
        position: fixed;
        right: 16px;
        width: 56px;
    }
}

.load-more-component {
    display: flex;
    height: 72px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.065);

    .btn-white {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            margin-left: 5px;
        }
    }
}


// ----------------------------------------


// TODO: are these styles used??
$button-color: #2196f3;


.button {
    display: inline-block;
    box-sizing: border-box;
    min-width: 64px;
    height: 36px;
    padding: 0 16px;
    border: 0;
    border-radius: 2px;

    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;

    text-transform: uppercase;
    text-align: center;
    line-height: 36px;
    text-decoration: none !important;

    cursor: pointer;
    color: $button-color !important;
}
.button.outline {
    border: 1px solid $button-color;
    color: $button-color !important;
    background-color: transparent;
    line-height: 34px;
}
.button.rounded {
    border-radius: 18px;
}
.button.primary {
    color: #fff !important;
    background-color: $button-color;
}
.button.raised {
    @include z-depth-2;
}
.button.reverse {
    color: #696969 !important;
    background-color: #fff;
}

.floating-action-button {
    position: fixed;
    bottom: 16px;
    right: 16px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    border-radius: 50%;

    text-align: center;
    line-height: 1;
    color: #fff !important;
    background-color: $button-color;
    cursor: pointer;
    @include z-depth-5;

    transition: all 0.3s ease-in-out;
}
.with-snackbar .floating-action-button {
    bottom: 64px;
}

.buttons {
    padding: 0;
    margin: 0;
}
.buttons.without-borders {
    margin: 0 -16px;
}
.buttons.right {
    text-align: right;
}
