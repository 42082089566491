

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: rgba(0, 0, 0, 0.3);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: white;
    width: 600px;
    height: 400px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.modal-anim-enter {
    opacity: .00;
    transition: all .2s;
}

.modal-anim-enter.modal-anim-enter-active {
    opacity: 1;
}

.modal-anim-leave {
    opacity: 1;
    transition: all .2s;
}

.modal-anim-leave.modal-anim-leave-active {
    opacity: .00;
}
