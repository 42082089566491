.animated {
    animation-duration: 1s;
    animation-fill-mode: both;

    &.infinite {
        animation-iteration-count: infinite;
    }

    &.animation-spin {
        animation-name: animation-spin;
        animation-timing-function: linear;
        transform-origin: center center;
    }

    &.animation-ripple {
        position: relative;
        overflow: hidden;

        &:before {
            animation-delay: 0s;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: ih-anim-ripple;
            animation-timing-function: ease-out;
            background-color: rgba(33, 150, 243, 0.25);
            border-radius: 50%;
            content: '';
            display: block;
            height: 400px;
            left: 50%;
            margin: -200px 0 0 -200px;
            position: absolute;
            top: 50%;
            transform-origin: center center;
            width: 400px;
        }
    }

    &.animation-pulsate {
        animation-delay: 1s;
        animation-duration: 1s;
        animation-name: animation-pulsate;
        transform-origin: center center;
        animation-timing-function: ease-in-out;
    }
}

@keyframes animation-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animation-ripple {
    0% {
        opacity: 1;
        transform: scale(0.1);
    }
    33% {
        opacity: 0.5;
        transform: scale(1);
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes animation-pulsate {
    0% {
        border-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        transform: scale(1);
    }
    60% {
        border-color: rgba(255, 255, 255, 1);
        border-width: 0;
        transform: scale(1.1);
    }
    61% {
        border-color: rgba(255, 255, 255, 0.5);
    }
    100% {
        border-color: rgba(255, 255, 255, 0);
        border-width: 52px;
        transform: scale(1);
    }
}

/*=========================================*/

[class^="ih-anim-"],
[class*=" ih-anim-"] {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

[class^="ih-anim-"].infinite,
[class*=" ih-anim-"].infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
/*
[class^="ih-anim-"].hinge,
[class*=" ih-anim-"].hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}
*/
@-webkit-keyframes ih-anim-bounce {
    0%, 20%, 53%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }

    40%, 43% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0);
    }
}

@keyframes ih-anim-bounce {
    0%, 20%, 53%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }

    40%, 43% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        -ms-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        -ms-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0,-4px,0);
        -ms-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0);
    }
}

.ih-anim-bounce {
    -webkit-animation-name: ih-anim-bounce;
    animation-name: ih-anim-bounce;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
}


@keyframes ih-anim-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ih-anim-spin {
    animation-name: ih-anim-spin;
    animation-delay: 0s;
    animation-timing-function: linear;
    transform-origin: center center;
//    transition-timing-function: linear;
}


@keyframes ih-anim-pulsate {
    0% {
        transform: scale(1.0, 1.0); opacity: 1.0;
    }
    100% {
        transform: scale(1.5, 1.5); opacity: 0.1;
    }
}
.ih-anim-pulsate {
    animation-name: ih-anim-pulsate;
    animation-delay: 0s;
    animation-timing-function: ease-out;
    transform-origin: center center;
//    transition-timing-function: linear;
}



@keyframes ih-anim-marquee {
    0% {
        transform: translate(0, 0);
    }
//    33% {
//        transform: translate(0, 0);
//    }
    100% {
        transform: translate(-100%, 0);
    }
}
.ih-anim-marquee > * {
    animation-name: ih-anim-marquee;
    animation-delay: 0s;

    animation-duration: 6s;
    animation-fill-mode: both;

//    animation-timing-function: ease-out;
    transform-origin: center center;
    transition-timing-function: linear;

}
.ih-anim-marquee.infinite > * {
    animation-iteration-count: infinite;
}


@keyframes ih-anim-ripple {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    33% {
        transform: scale(1);
        opacity: 0.5;
    }
    66% {
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
.ih-anim-ripple {
    position: relative;
    overflow: hidden;
}
.ih-anim-ripple-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.ih-anim-ripple-effect,
.ih-anim-ripple:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 400px;
    height: 400px;
    margin: -200px 0 0 -200px;
    border-radius: 50%;

    background-color: rgba(255,255,255,0.25);

    animation-name: ih-anim-ripple;
    animation-delay: 0s;

    animation-duration: 1.5s;
    animation-fill-mode: both;

    animation-timing-function: ease-out;
    transform-origin: center center;
    animation-iteration-count: infinite;
}
.ih-anim-ripple-effect {
    animation-iteration-count: 1;
}
.ih-anim-ripple-effect.infinite {
    animation-iteration-count: infinite;
}
.ih-anim-ripple-effect.blue,
.ih-anim-ripple.blue:before {
    background-color: rgba(33, 150, 243, 0.25);
}
.ih-anim-ripple-effect.gray,
.ih-anim-ripple.gray:before {
    background-color: rgba(150, 150, 150, 0.25);
}



/*
    // sample code for jQuery keyframe event binding
    // note: callbck never gets fired if running "infinite"
    $('#animationSandbox').removeClass().addClass(x + ' animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
        $(this).removeClass();
    });
*/
