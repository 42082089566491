// mixins
// When hover over collapsed nav, it becomes normal nav again, the css is used twice, both normal state & hover state
// So put those shared style in mixins, call those mixins once when it's normal nav, and another when hover over collapsed nav
@mixin normalSidePanelHeader() {
    text-align: left;
    line-height: $l_header_height;
    padding: 0;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);

    .brand {
        .icon {
            margin-right: 24px;
            margin-bottom: -3px;
        }
    }

    .brand-text {
        display: inline;
    }

    .collapsednav-toggler {
        display: inline-block;
    }
}
@mixin normalSidePanelContent() {
    .nav-header > span {
        display: inline;
    }

    .menu-item-text {
        display: inline;
        text-align: left;
    }

    .icon-has-ul {
        display: inherit;
    }

    .badge {
        display: inherit;
        top: 14px;
        right: 35px;
    }

    .nav > li {
        &:first-child {
            margin-top: 10px;
        }
        // 1st level ul
        > a, .menu-action {
            padding: 12px 16px !important;
            text-align: left !important;

            .nav-icon {
                transition: padding .35s $l_transition_ease_out, margin .35s $l_transition_ease_out;
                margin-right: 18px;
            }
        }

        .icon {
            vertical-align: middle;
            margin: -2px 26px 0 0;
        }

        // 2nd level ul
        ul > li > a {
            text-align: left !important;
        }
    }
}




@mixin lightNavTheme() {
    .nav {
        color: $color-nav-item;

        a {
            color: $color-nav-item;
        }

        .nav-header {
            color: $text-muted;
        }

        .nav-divider {
            background-color: rgba(0,0,0,.15);
        }

        li {
            > a, .menu-action {
                &:focus,
                &:hover {
                    background-color: $bg-color-button-hover;
                    color: $color-nav-item-hover;
                }
            }
            &.open {
                > a, .menu-action,
                > a:hover, .menu-action:hover,
               > a:focus, .menu-action:focus {
                   background-color: $bg-color-button-hover;
               }
            }
            &.selected,
            &.active {
                 > a, .menu-action
                 > a:hover, .menu-action:hover,
                > a:focus, .menu-action:focus {
                    background-color: $bg-color-button-active;
                    color: $color-nav-item-active;
                }
            }
        }

        // sub ul
        ul {
            background-color: $bg-color-button-hover;

            li {
                &.selected,
                &.active,
                &.open {
                     > a:focus,
                     > a:hover,
                    > a {
                        background-color: $bg-color-button-hover;
                    }
                }
            }
        }
    }
}
@mixin darkNavTheme() {
    .nav {
        color: $color-nav-item-dark;

        a {
            color: $color-nav-item-dark;
        }

        .nav-header {
            color: $text-muted;
        }

        .nav-divider {
            background-color: rgba(0,0,0,.15);
        }

        li {
            > a, .menu-action {
                &:focus,
                &:hover {
                    background-color: transparent;
                    color: $color-nav-item-hover-dark;
                }
            }

            &.open {
                > a, .menu-action,
                > a:hover, .menu-action:hover, 
                > a:focus,.menu-action:focus {
                   background-color: $bg-color-button-hover-dark;
                }
            }

            &.selected,
            &.active {
                > a, .menu-action, 
                > a:hover, .menu-action:hover,
                > a:focus, .menu-action {
                    background-color: $bg-color-button-active-dark;
                    color: $color-nav-item-active-dark;
                }
            }

            &.animated {
                > a, .menu-action,
                > a:hover, .menu-action:hover,
                > a:focus, .menu-action {
                    color: $color-nav-item-active-dark;
                }
            }
        }

        // sub ul
        ul {
            background-color: $bg-color-button-hover-dark;

            li {
                &.selected,
                &.active,
                &.open {
                    > a:focus,
                    > a:hover,
                    > a {
                        background-color: $bg-color-button-hover-dark;
                    }
                }
            }
        }
    }
}

// layout
.side-panel {
    display: flex;
    flex-flow: column nowrap;
    // z-index: $l_zindex_side_panel_mobile;
    z-index: 999999;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100vh; // Fix(Safari): Fix side-panel fail to on safari
    width: $l_side_panel_width;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;

    // light theme for mobile
    background-color: $l_white;

    @include lightNavTheme();


    transition: 300ms;


    @media only screen and (min-width: $l_screen_md_min) {
        z-index: 1001;
        overflow-y: visible;
        white-space: nowrap;
    }


    &.side-panel-left {
        left: -$l_side_panel_width;
        right: auto;

        @media only screen and (min-width: $l_screen_md_min) {
            left: 0;
            background-color: $l_gray_dark;
            box-shadow: 1px 0 2px rgba(0,0,0,.15);
            @include darkNavTheme();
        }
    }

    &.side-panel-right {
        right: -$l_side_panel_width;
        left: auto;

        @media only screen and (min-width: $l_screen_md_min) {
            // right: 0;
        }

        // .drawer-content {
        //     .drawer-close {
        //         right: 4px;
        //         left: auto;
        //     }
        // }
        header {
            position: relative;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            height: 56px;
            padding: 0 0 0 24px;
            color: #fff;
            background: linear-gradient(to bottom, #e44c00, #d41d00);
            // background-color: rgb(247, 105, 0);
            // margin-bottom: 1em;

            span {
                display: block;
                height: 48px;
                padding: 0;
                font-size: 20px;
                line-height: 48px;
                color: #fff;
                box-sizing: border-box;
            }

            @media only screen and (min-width: $l_screen_md_min) {
                height: 60px;
                min-height: 60px;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
            }
        }
        .filter-body {
            padding: 10px 12px;

            // This should only turn blue when the field
            // has a value or if it is focused - which already works...
            // label {
            //     color: #48aeff;
            // }
        }
        .side-panel-close {
            left: auto;
            right: 0;

            @media only screen and (min-width: $l_screen_md_min) {
                top: 6px;
            }
        }
    }


    .side-panel-header {
        @include normalSidePanelHeader();
        position: relative;

        display: block;
        flex: 0 0 auto;
        height: 172px;
        background-image: url(/assets/images/navigation-drawer-header.png);
        // background-color: #e44c00;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        @media only screen and (min-width: $l_screen_md_min) {
            height: 60px;
        }

        .brand {
            position: absolute;
            bottom: 0;
            display: block;
            min-width: 64px;
            padding: 0 16px;
            font-weight: normal;
            font-size: 20px;
            color: #fff;
            line-height: $l_header_height;
            text-decoration: none !important;

            .icon {
                margin-right: 24px;
                margin-bottom: -3px;
            }

            > a {
                color: #fff !important;
            }
        }

        .side-panel-actions {
            position: absolute;
            top: 0;
            left: 0;
            right: 8px;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (min-width: $l_screen_md_min) {
                display: none;
            }
            // background-color: rgba(0,0,0,0.05);

            a {
                color: #fff;
            }
            .badge {
                display: none;
            }

            .toolbar-icon {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                // border-left: 1px solid rgba(255,255,255,0.2);
            }

            // background circles for right hand icons
            .toolbar-icon:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 32px;
                height: 32px;
                margin: -16px 0 0 -16px;
                padding: 0;
                // background-color: rgba(0,0,0,0.15);
                background-color: rgba(204, 60, 10, 0.41);
                border-radius: 50%;
            }
            .user-account-icon .circle-front {
                background-color: #666;
            }
            .user-account-icon.toolbar-icon:before {
                display: none;
            }
            .toolbar-button {
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                width: 1*$units;
                height: 1*$units;

                margin: 0;
                padding: 0;

                text-align: center;

                .icon {
                    font-size: 24px;
                }
            }
            .avatar-circle .circle {
                @include z-depth-1-half;

                .circle-front {
                    background-color: #636363;
                }
            }
        }

        a.collapsednav-toggler {
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            right: 18px;
            top: 20px; // (60 - 20) /2
            color: rgba(255,255,255,.30);
            transition: transform 0.3s ease-in-out;

            &:hover {
                color: rgba(255,255,255,1);
            }

            @media only screen and (max-width: $l_screen_sm_max ) {
                display: none;
            }

            .icon {
                transition: transform 0.3s ease-in-out;
            }
        }
    }

    .side-panel-content {
        flex: 0 0 auto;

        @include normalSidePanelContent();
        // height: calc(100% - #{$l_header_height + $side_panel_footer_height} ); // calc is a simple sultion with a bit cross browser support problem
        // height: calc(100% - #{$l_header_height} );

        @media only screen and (min-width: $l_screen_md_min) {
            height: calc( 100% - 60px );
        }
    }


    ul.nav {
        flex-direction: column;
        ul {
            display: none; // hide all sub ul on init
        }

        li {
            position: relative;

            &.nav-divider {
                min-height: 1px;
                margin: 10px 0 10px;
                overflow: hidden;
            }

            div {
                // counteract elite reset where all divs set to position: relative
                position: static;
            }
        }

        > li:last-child {
            padding-bottom: 48px;
        }
    }

    .side-panel-close {
        position: absolute;
        top: 4px;
        left: 4px;
        right: auto;
        height: 48px;
        width: 48px;
        color: rgba(255,255,255,1);
        line-height: 60px;
        text-align: center;
        cursor: pointer;

        &:hover {
            color: rgba(255,255,255,1);
        }

        &.side-panel-left {
            @media only screen and (min-width: $l_screen_md_min) {
                display: none;
            }
        }
    }
}


/*
    Active state
    while dragging and open
*/
.side-panel.active {
    // z-index: 2001;
    z-index: 9011;

    &.side-panel-left {
        left: 0;
        box-shadow: 1px 0 12px rgba(0,0,0,0.4);

        @media only screen and (min-width: $l_screen_md_min) {
            box-shadow: none;
        }
    }

    &.side-panel-right {
        right: 0;
        box-shadow: -1px 0 12px rgba(0, 0, 0, 0.4);
    }

}

.side-panel.panning {
    z-index: 9011;
    box-shadow: 3px 0 4px rgba(0,0,0,0.4);
    transition: unset;

    .overlay {
        transition: unset;
    }
}


/*
    Filter drawer specific styling
*/

.side-panel-filter {
    header {
        flex: none;
    }

    label {
        color: #48aeff;
    }
}

/*
    Collapsed state
    only relevant for desktop size
*/
@media only screen and (min-width: $l_screen_md_min) {
    .nav-collapsed {
        .collapsednav-toggler .icon {
            transform: rotate(180deg);
        }

        .side-panel-left {
            width: $l_side_panel_width_collapsed;

            .icon-submenu-toggle {
                display: none;
            }

            .side-panel-header {
                text-align: center;

                .brand {
                    .icon {
                        margin-right: 0;
                    }
                }

                .brand-text {
                    display: none;
                }

                .collapsednav-toggler {
                    display: none;
                }
            }

            .side-panel-content {
                .icon-has-ul,
                .nav ul a > span,
                .nav-header > span,
                .menu-item-text {
                    // sub ul text
                    display: none;
                }

                .nav ul a > div {
                    // for materail-ui FlatButton
                    > span {
                        display: none;
                    }
                }

                .badge {
                    top: 3px;
                    right: 5px;
                }

                .nav > li {
                    &:first-child {
                        margin-top: 10px;
                    }


                    > a {
                        padding: 12px 16px !important;
                        text-align: center !important;
                    }

                    .icon,
                    .nav-icon {
                        margin-right: 0;
                    }
                    // because of jQuery
                    ul {
                        li {
                            > a {
                                text-align: center !important;

                                > .nav-icon {
                                    margin-right: 0;
                                }

                                > div > .nav-icon {
                                    // for materail-ui FlatButton
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .side-panel-footer {
                .menu-item-text {
                    display: none;
                }

                .nav > li {
                    > a {
                        text-align: center !important;
                    }

                    .icon,
                    .nav-icon {
                        margin-right: 0;
                    }
                }
            }

            .side-panel-ad {
                display: none;
            }

            // while hovering reset to normal uncollapsed style
            &:hover:not(.collapsing) {
                width: $l_side_panel_width;
                // solve ".badge, .icon-has-ul floating on text when hover over collapsed nav" problem
                // however this effect doesn't apply to remove/add `.nav-collapsed` class using ng-class
                > * {
                    width: $l_side_panel_width;
                }

                .side-panel-header {
                    @include normalSidePanelHeader();
                }

                .side-panel-content {
                    @include normalSidePanelContent();
                }

                .icon-submenu-toggle {
                    display: block;
                }

                .side-panel-ad {
                    display: block;
                }
            }
        }
    }
}



// overall


// side-panel nav, for side-panel-content (accordionNav), side-panel-footer
.side-panel .nav {
    // General
    a, .menu-action {
        display: block;
        position: relative;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }
    }

    li {
        position: relative;

        .badge {
            position: absolute;
        }
    }
    .nav-header {
        margin: 15px 15px 5px;
        font-size: $font-size-sm;
        text-transform: uppercase;
        visibility: visible;
    }

    .nav-divider + .nav-header {
        margin-top: 5px;
    }

    // first level li
    > li {
        > a {
            line-height: 24px;
        }
    }
    .nav-icon {
        display: inline-block;

        &.nav-icon {
            width: 24px;
            height: 24px; // with font-size 20px, material-icons will be at least 25px high
            font-size: 18px;
            line-height: 24px;
            text-align: center;

            &.nav-dot {
                font-size: 16px;
            }
        }
    }
    // sub ul
    ul {
        list-style: none;
        padding: 0;

        li {
            > a {
                padding: 10px 16px !important; // Align with 1st level: 18px = 16px (1st level) + 2px ()

                .nav-icon {
                    width: 20px;
                    height: 20px; // with font-size 20px, material-icons will be at least 25px high
                    font-size: 16px;
                    line-height: 1;
                    text-align: center;
                    vertical-align: middle;
                    margin-bottom: -2px;
                }
                .icon-submenu-item {
                    transform: rotate(-90deg);
                    fill-opacity: 0.5;
                }
            }
        }
    }
    .icon-submenu-toggle {
        position: absolute;
        top: 12px;
        right: 16px;
        margin: 0 !important;
        color: rgba(150,150,150,0.4) !important;
        cursor: pointer;
        transform: rotate(0deg);
        transition: 0.3s transform ease-in-out;
    }
    li.open > a > .icon-submenu-toggle {
        transform: rotate(-180deg);
    }
}

.side-panel-nav {
    @include disable-text-selection;
}

// Multi-level nav color
// Default dark background style
// use with directive "accordionNav", which add .open class on li


// .app-turboleads {
//     .side-panel-header {
//         background: $turboleads-gradient-red;
//     }
// }


/*
    For the mobile app version,
    hide certain buttons for now
*/
.mobile-app {
    .apps-icon,
    .notifications-icon {
        display: none !important;
    }
}
