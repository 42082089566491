.ihouseweb-react-tooltip {
    border-radius: 0 !important;
    padding: 5px 8px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 12px !important;
    line-height: 18px;
    opacity: 1 !important;
    z-index: 9999 !important;

    &.place-left {
        margin-left: -5px !important;
    }

    &.place-right {
        margin-left: 7px !important;
    }
}
