.avatar {
    border-radius: 50%;
    @include responsive-img;
}

.avatar-initials,
.circle-text {
    font-family: 'Roboto Condensed', Arial Narrow, sans-serif;
    font-size: 1.25rem; // 18px
    font-weight: 100;
}

.avatar-photo {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    top: 0;
    left: 0;
}

.circle-container {
    position: relative;
    perspective: 1000px;

}
.circle-container,
.circle-front,
.circle-back {
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0,0);
}
.circle {
    transition: 0.5s;
    transform-style: preserve-3d;
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
}
.circle {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.circle-front,
.circle-back {
    border-radius: 50%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateX(0deg);
    webkit-transform: translate(0,0);
    -webkit-transition-property: top, bottom;
    -webkit-transition-duration: 0.5s;


    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.circle-front {
    background-color: #d2d2d2;
    z-index: 2;
}
.circle-back {
    -webkit-transform: rotate3d(0,45,0,180deg);
    transform: rotate3d(0,45,0,180deg);
    background-color: rgba(0,0,0,0.25);
}
