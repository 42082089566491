@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:regular,bold|Roboto+Condensed:light');

html {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-flow: column nowrap;
    font-family: 'Open Sans', sans-serif !important;
    margin: 0;
    min-height: 100%;
    padding: 0;
}

body.iOS {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.no-overflow {
    overflow: hidden;
}

blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.12);
    margin: 0 0 1rem;
    padding-left: 16px;
}

p.instructions {
    padding: 16px;
}

.toastify-content--success {
    background: $green !important;
}
.toastify-content--progress {
    background: #000000 !important;

    .progress {
        height: 20px;
    }
}

.toastify {
    z-index: 99999;
}

.subheader {
    color: #2196f3;
    font-size: 14px;
    margin-top: 16px;
    padding: 13px 16px 14px 16px;
    position: relative;
}

.turboleads-logo {
    fill: #fff;
    display: inline-block;
}

.clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

/* links that don't look like links */
a.unclickable {
    color: inherit;
    cursor: default;
    text-decoration: none !important;
}


blockquote {
    margin: 0 0 1rem;
    padding-left: 16px;
    border-left: 3px solid rgba(0, 0, 0, 0.12);
}

/* force our instance of react tooltip to have a higher z-index */
.turboleads-react-tooltip {
    z-index: 9999 !important;
}

.placeholder {
    color: #aaa;
    padding-right: 12px;
}
