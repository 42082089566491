// layout
.toolbar {
    position: relative;
    z-index: $l_zindex_navbar;

    display: block;
    width: 100%;
    padding: 0;
    border: 0; // no border, otherwise other elements' height can only be ($l_header_height -1)

    text-align: center;
    background-color: $l_white;
    box-shadow: $header_box_shadow;

    .toolbar-inner {
        display: flex;
        flex-flow: row nowrap;
    }

    .top-nav-left,
    .top-nav-right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .top-nav-left {
        justify-content: flex-start;
        flex: 1 0 auto;
    }
    .top-nav-right {
        justify-content: flex-end;
        flex: 0 1 auto;
    }

    a {
        color: #292b2c;
    }

    .toggle-side_panel {
        display: block;

        @media only screen and (min-width: $l_screen_md_min) {
            display: none;
        }
    }

    .brand {
        text-align: left;

        @media only screen and (min-width: $l_screen_md_min) {
            flex: 0 1 auto;
            width: $l_side_panel_width;
            padding: 0 0 0 $l_side_panel_width_collapsed;
            transition: padding 0.25s $l_transition_ease_out;
        }

        h2 {
            font-size: 24px;
            margin: 0;
            line-height: $l_header_height;
            padding-left: 16px;
        }

        a {
            @media only screen and (min-width: $l_screen_md_min) {
                text-decoration: none;
                font-weight: normal;
            }
        }
    }

    .toolbar-title {
        text-align: left;

        @media only screen and (min-width: $l_screen_md_min) {
            flex: 0 1 auto;
            padding: 0 0 0 $l_side_panel_width;
            transition: padding 0.25s $l_transition_ease_out;
        }

        h1 {
            font-size: 20px;
            margin: 0;
            line-height: $l_mobile_header_height;
            padding-left: 26px;

            @media only screen and (min-width: $l_screen_md_min) {
                padding-left: 16px;
                font-size: 24px;
                line-height: $l_header_height;
            }

            &:empty {
                padding-left: 0 !important;
            }
        }
    }

    .action-icon {
        position: relative;
    }
    .action-text {
        position: relative;
    }

    .dropdown-component {
        .dropdown-icon {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            width: 48px;
            height: 1*$units;

            margin: 0;
            padding: 0;
            text-align: center;

            @media only screen and (min-width: $l_screen_md_min) {
                height: $l_header_height;
                width: 1*$units;
            }

            .icon {
                font-size: 24px;
            }
        }
        .menu {
            padding-top: 8px;
            padding-right: 8px;
        }
    }

}


/* need this defined outside of .toolbar context
for when displaying action-buttons in feature tip modals */
.action-button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 48px;
    // height: 48px;
    height: 1*$units;

    margin: 0;
    padding: 0;

    text-align: center;

    @media only screen and (min-width: $l_screen_md_min) {
        height: $l_header_height;
        width: 1*$units;
    }

    &.action-button-auto {
        width: auto !important;

        .icon {
            margin-right: 24px;
        }
    }

    .icon {
        font-size: 24px;
    }
}
.action-text {
    .action-button {
        padding: 0 16px;
        text-transform: uppercase;
        text-decoration: none !important;
    }
}

.fixed-header {
    .toolbar {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
    }
}

#page-container > .sticky-outer-wrapper {
    position: relative;
    z-index: 1000;
}


.nav-collapsed {
    .app-toolbar {
        .toolbar-title {
            @media only screen and (min-width: $l_screen_md_min) {
                padding-left: $l_side_panel_width_collapsed;
            }
        }
        .top-nav-left .menu {
            right: auto;
            left: $l_side_panel_width_collapsed;
        }
    }
}

// @media only screen and (min-width: $l_screen_md_min) {
//     .nav-behind {
//         .toolbar {
//             .brand {
//                 padding-left: 0;
//             }
//         }
//     }
// }



/*
    App toolbar
*/
.app-toolbar {

    // fast, subtle transition - just enough to take the edge off
    .action-button,
    .dropdown-component .dropdown-icon {
        position: relative;
        transition: 0.2s all ease-in-out;

        // button states
        &:hover {
            background-color: rgba(0,0,0,0.05);
        }
        &.active {
            background-color: rgba(0,0,0,0.05);
        }
    }

    // badges
    .action-icon,
    .action-text {
        .action-button .badge {
            position: absolute;
            top: 6px;
            right: 4px;
            background-color: #333;

            @media only screen and (min-width: $l_screen_md_min) {
                right: 6px;
                background-color: #0275d8;
            }
        }
    }

    // no room for the page title in desktop view -
    // instead it gets it's own masthead in content
    .toolbar-title {
        min-width: 0;
    }
    .toolbar-title h1 {
        @include text-overflow-ellipsis;

        @media only screen and (min-width: $l_screen_md_min) {
            display: none;
        }
    }

    .top-nav-left {
        flex: 1 1 auto;

        .menu {
            top: 60px;
            right: auto;
            left: $l_side_panel_width;
        }
        .edit-icon,
        .view-icon,
        .website-domain-icon {
            display: none;
        }

        .website-domain-button {
            &.no-selection {
                &:hover {
                    background-color: #fff;
                    cursor: default;
                }
            }
        }

        @media only screen and (min-width: $l_screen_md_min) {
            .edit-icon,
            .view-icon,
            .website-domain-icon {
                display: block;
            }
        }
    }


    .top-nav-right {
        // manual menu positioning
        .apps-menu,
        .notifications-menu,
        .user-account-menu {
            top: 60px;
        }

        .help-icon,
        .apps-icon,
        .notifications-icon,
        .user-account-icon {
            display: none;
        }

        @media only screen and (min-width: $l_screen_md_min) {
            .action-icon,
            .action-text {
                display: none;
            }
            .help-icon,
            .apps-icon,
            .notifications-icon,
            .user-account-icon {
                display: block !important;
            }
        }
    }
    @media only screen and (min-width: $l_screen_md_min) {
        // lines between buttons
        .top-nav-left .action-icon {
            border-right: 1px solid rgba(0,0,0,0.1);
        }
        .top-nav-right .action-icon {
            border-left: 1px solid rgba(0,0,0,0.1);
        }
    }
}


.app-turboleads {
    .app-toolbar {

        background: $turboleads-gradient-red;
        @media only screen and (min-width: $l_screen_md_min) {
            background: #fff;
            color: $body-color;
        }

        .toolbar-inner {

            color: #fff;
            a {
                color: #fff;
                @media only screen and (min-width: $l_screen_md_min) {
                    color: $body-color;
                }
            }

            @media only screen and (min-width: $l_screen_md_min) {
                background: #fff;
                color: $body-color;
                a {
                    color: $body-color;
                }
            }

            &.select-mode {
                background: #1d529a;
            }

            &.search-mode {
                background: #ffffff;
                color: $body-color;

                a {
                    color: $body-color;
                }

                input[type="text"] {
                    width: 100%;
                    height: 56px;
                    box-sizing: border-box;
                    border: 0;
                    font-size: 16px;
                    padding: 0 16px;
                }
            }
        }
    }
}

.app-website {
    .app-toolbar {

        background: $ihouse-gradient-blue;
        @media only screen and (min-width: $l_screen_md_min) {
            background: #fff;
            color: $body-color;
        }


        .toolbar-inner {
            color: #fff;
            a {
                color: #fff;
                @media only screen and (min-width: $l_screen_md_min) {
                    color: $body-color;
                }
            }

            a.website-domain-button {
                padding: 0 16px 0 24px !important;
                text-decoration: none !important;
                font-size: 16px;

                .icon {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
.app-leadtracker {
    .app-toolbar {
        .toolbar-inner {
            background: $ihouse-gradient-blue;
            color: #fff;
            a {
                color: #fff;
            }

            @media only screen and (min-width: $l_screen_md_min) {
                background: #fff;
                color: $body-color;
                a {
                    color: $body-color;
                }
            }
        }
    }
}

.app-account {
    .app-toolbar {
        background: $ihouse-gradient-blue;
        color: #fff;
        @media only screen and (min-width: $l_screen_md_min) {
            background: #fff;
            color: $body-color;
        }

        .toolbar-inner {

            a {
                color: #fff;
                @media only screen and (min-width: $l_screen_md_min) {
                    color: $body-color;
                }
            }

            a.back-to-website-admin-button {
                padding: 0 24px !important;
                text-decoration: none !important;
                font-size: 16px;
            }
        }
    }
}

// active state of the menu toggle
.side-panel-mobile-open .app-toolbar .toggle-side-panel-btn {
    background-color: rgba(0,0,0,0.05);
}
