//
// Base styles
//

.alert {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  margin-top: $alert-margin-top;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

@media only screen and (max-width: $bp-gt-sm-tablet-portrait) {
    .alert-wrapper {
        margin: 0 8px 16px 8px;
    }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
    // Adjust close link position
    .close {
        position: relative;
        top: -$alert-padding-y;
        right: -$alert-padding-x;
        padding: $alert-padding-y $alert-padding-x;
        color: #000;
    }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

//.alert-success {
//  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
//}
//.alert-info {
//  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
//}
//.alert-warning {
//  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
//}
//.alert-danger {
//  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
//}


//hijacked styles

.alert-success {
  @include alert-variant(#62B255, transparent, #FFF9F8);
}
.alert-info {
  @include alert-variant(#4C95E8, transparent, #FFF9F8);
}
.alert-warning {
  @include alert-variant(#F27532, transparent, #FFF9F8);
}
.alert-danger {
  @include alert-variant(#D63737, transparent, #FFF9F8);
}


