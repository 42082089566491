


// material design grid units
$units: 56px;





// map Bootstrap variables here so that Bootstrap is not required for essential layout
// prefix with `l_`
// Note: '_' and '-' are identical, e.g. if bootstrap _var.scss is loaded, $body_bg will be compiled with value of $body-bg

// color
$l_body_bg:     $body-bg;
$l_white:       #fff;       // for app-page-container, quickview, header
$l_gray_dark:   #333C44;    // for nav

// screen
$l_screen_md_min: map-get($grid-breakpoints, lg);       // 992px
$l_screen_sm_max: (map-get($grid-breakpoints, lg) - 1); // 991px

// zindex
$l_zindex_page_container_mobile: 100;
$l_zindex_side_panel_mobile:        ($l_zindex_page_container_mobile - 1);
$l_zindex_navbar:                $zindex-navbar;
$l_zindex_side_panel:               ($l_zindex_navbar + 1);
$l_zindex_quickview:             ($l_zindex_navbar + 1);
$l_zindex_overlay:               $zindex-modal-backdrop;

// Layout
$l_header_height:               60px;
$l_mobile_header_height:        48px;

$l_side_panel_width_collapsed:     64px;  // Desktop only: collpased side_panel
$l_side_panel_width:               280px; // Desktop: side_panel; Mobile: off-canvas side_panel

$l_right_quickview_width:       300px;

$l_layout_boxed_max_width:      1200px;

$l_units:                       56px;


// UI
$container_fluid_maxwidth:      1400px;


// Motion
// https://material.google.com/motion/duration-easing.html#duration-easing-natural-easing-curves
$l_transition_bezier: .05, .74, .27, .99; // for nav, quickview

$l_transition_ease:          cubic-bezier(0.4,0.0,0.2,1);
$l_transition_ease_out:      cubic-bezier(0.0,0.0,0.2,1); // Used on side_panel related
$l_transition_ease_in:       cubic-bezier(0.4,0.0,1,1);

// $l_transition_ease_out_side_panel:   cubic-bezier(.25,.75,.25,.99);
$l_side_panel_collpase_duration:    .3s;
$l_transition_ease_out_quickview: cubic-bezier(.05,.74,.2,.99);




// Themes
// --------------------------------------------------

$header_line_height: 24px;
$header_box_shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); // .z-depth-1

$side_panel_footer_height:     44px;



// side_panel width, just for demo
// in real project, simply change '$l_side_panel_width' to any value u want
$l_side_panel_width_sm:               220px;
$l_side_panel_width_lg:               280px;
