#react-root {
    height: 100%;
}

.react-root {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    position: relative;

    .page {
        flex: 1 0 100%;

        &.card-page {
            background-color: #eee;
        }

        &.list-page {
            .page-content {
                padding: 0 16px;
            }
        }

        &.form-page {
            .page-content {
                padding: 8px 16px;
            }
        }
    }
}
