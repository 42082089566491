/*
    Turboleads specific card styles
*/
.card {
    .card-header {
        padding: 0 8px 0 16px;
    }

    &.info-card {
        padding: 16px;
        color: #f34e00;

        .card-content {
            width: 100%;
            display: flex;
            align-items: center;

            .icon-info-outline {
                flex-shrink: 0;
                margin-right: 12px;
            }
        }
    }

    &.picture-card {
        box-shadow: none;

        .card-content {
            img {
                width: 100%;
                height: 100%;
            }
        }

        .card-bar {
            background-color: rgba(0,0,0,0.75);

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            height: 68px;

            .bar-title, .bar-sub-title, .dropdown-icon {
                color: white;
            }

            .bar-title {
                margin-bottom: 3px;
            }

            .bar-sub-title {
                font-size: 12px;
                font-weight: 100;
            }

            .bar-title, .bar-sub-title {
                padding-right: 16px;
            }
        }
    }

    &.listing-pictures-card {
        margin-bottom: 10px;
        margin-top: 0;
        max-height: 1200px;
        overflow-y: scroll;
        overflow-x: hidden;

        .card-content {
            .picture-card {
                margin: 0;
                .card-content {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }


    &.usage-card {
        //@media (max-width: $bp-lt-sm-tablet-landscape) {
        //    margin-bottom: 3em;
        //}

        &.simple-border {
            box-shadow: none;
            border: 2px solid rgba(0, 0, 0, 0.125);
        }

        .card-content {
            padding: 8px 16px;


            @media (max-width: $bp-lt-sm-tablet-landscape) {
                ul {
                    flex-direction: column;
                }
            }

            ul {
                display: flex;

                li {
                    flex-shrink: 0;
                    margin-right: 16px;
                }
            }
        }
    }

    &.timeline-card {
        .filter-tabs {
            margin: 16px 0 0 16px;
            padding-bottom: 16px;
        }

        .card-footer {
            text-align: left;

            .btn {
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                text-transform: none;

                .icon {
                    transition: 300ms ease-in-out;
                }

                &.expanded {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.sample-listing-flyer-card {
        .card-content {
            display: flex;
            height: 100%;
        }
    }

    &.add-edit-campaign-card {
        .card-content {
            .text-field-component,
            .read-only-field-component {
                padding: 0 16px;
            }

            h2 {
                margin: 14px 0 4px 16px;
                padding-bottom: 0;
                border-bottom: none;
            }

            .empty-list {
                text-align: left;
            }
        }
    }

    &.auto-start-triggers-card {
        .card-content {
            .auto-start-trigger-switch {
                padding: 8px 16px;
                display: flex;
                align-items: center;
                align-content: center;

                span {
                    margin-right: 8px;

                    .checkbox-icon-component {
                        margin-top: 4px;
                    }
                }
            }

            .auto-start-trigger-mode-conditions {
                padding: 8px 16px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;

                > div {
                    display: flex;
                    align-items: flex-start;

                    > span {
                        margin-top: 13px;
                    }

                    .select-field-component {
                        margin: 0 12px;
                        width: 60px;
                    }
                }

                span {
                    font-size: 15px;
                }

                @media (min-width: $bp-sm-tablet-portrait) {
                    flex-direction: row;

                    > span {
                        margin-top: 13px;
                    }
                }
            }

            .empty-list {
                text-align: left;
            }
        }
    }
}

.image-spacer-3-2 {
    width: 100%;
    padding-bottom: 66%;
}
