//$color-a: #36a943;
//$color-b: #453d3f;
//$color-c: #d70206;
//$color-d: #f05b4f;
//$color-e: #f4c63d;
//$color-f: #d17905;
//$color-g: #0544d3;
//$color-h: #6b0392;
//$color-i: #995b4f;
//$color-j: #dda458;
//$color-k: #eacf7d;
//$color-l: #86797d;
//$color-m: #6188e2;
//$color-n: #a748ca;
//$color-o: #b2c326;

$color-a: #36a943;
$color-b: #453d3f;
$color-c: #C40206;
$color-d: #E14749;
$color-e: #f34e00;
$color-f: #F28532;
$color-g: #053B71;
$color-h: #0079B2;
$color-i: #4C00AF;
$color-j: #8D44F4;
$color-k: #4C95E8;
$color-l: #86797d;
$color-m: #6188e2;
$color-n: #a748ca;
$color-o: #62B255;

.ct-color-a { .ct-key-label { border-color: $color-a; color: $color-a; } &.ct-series { stroke: $color-a; } }
.ct-color-b { .ct-key-label { border-color: $color-b; color: $color-b; } &.ct-series { stroke: $color-b; } }
.ct-color-c { .ct-key-label { border-color: $color-c; color: $color-c; } &.ct-series { stroke: $color-c; } }
.ct-color-d { .ct-key-label { border-color: $color-d; color: $color-d; } &.ct-series { stroke: $color-d; } }
.ct-color-e { .ct-key-label { border-color: $color-e; color: $color-e; } &.ct-series { stroke: $color-e; } }
.ct-color-f { .ct-key-label { border-color: $color-f; color: $color-f; } &.ct-series { stroke: $color-f; } }
.ct-color-g { .ct-key-label { border-color: $color-g; color: $color-g; } &.ct-series { stroke: $color-g; } }
.ct-color-h { .ct-key-label { border-color: $color-h; color: $color-h; } &.ct-series { stroke: $color-h; } }
.ct-color-i { .ct-key-label { border-color: $color-i; color: $color-i; } &.ct-series { stroke: $color-i; } }
.ct-color-j { .ct-key-label { border-color: $color-j; color: $color-j; } &.ct-series { stroke: $color-j; } }
.ct-color-k { .ct-key-label { border-color: $color-k; color: $color-k; } &.ct-series { stroke: $color-k; } }
.ct-color-l { .ct-key-label { border-color: $color-l; color: $color-l; } &.ct-series { stroke: $color-l; } }
.ct-color-m { .ct-key-label { border-color: $color-m; color: $color-m; } &.ct-series { stroke: $color-m; } }
.ct-color-n { .ct-key-label { border-color: $color-n; color: $color-n; } &.ct-series { stroke: $color-n; } }
.ct-color-o { .ct-key-label { border-color: $color-o; color: $color-o; } &.ct-series { stroke: $color-o; } }

.chart-component {
    .ct-chart {
        max-width: 440px;

        .ct-series {
            .ct-slice-donut {
                opacity: 0.6;
              }

            &.ct-selected {
                .ct-slice-donut {
                    opacity: 1;
                    stroke-width: 70px !important;
                }
            }
        }

        .ct-label {
            color: #fff;
            fill: #fff;
        }

        &.ct-single {
            .ct-label {
                color: #aaa;
                fill: #aaa;
            }
        }

        &.ct-empty {
            .ct-slice-donut {
                stroke: #ddd;
            }
        }
    }

    ul {
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;

            li {
                align-items: center;
                cursor: pointer;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                margin-bottom: 1px;
                min-height: 40px;
                padding: 0 16px 0 0;

            span {
                display: inline-block;
                vertical-align: middle;
            }

            .ct-key-label {
                border-left-width: 4px;
                border-left-style: solid;
                opacity: 0.6;
                padding: 8px 12px;
            }

            .ct-key-value,
            .ct-key-percent {
                @include small;
            }

            .ct-key-value {
                color: #666;
                font-weight: bold;
                margin-right: 4px;
            }

            &.ct-selected {
                .ct-key-label {
                      opacity: 1;
                      border-left-width: 12px;
                }
            }
        }
    }
}