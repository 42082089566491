.user-card {
    background: #e44c00 url(/assets/images/navigation-drawer-header.png) no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    height: 172px;
    justify-content: flex-end;
    padding: 0;
    position: relative;

    .brand {
        bottom: 0;
        font-size: 23px;
        height: 56px;
        left: 0;
        line-height: 56px;
        padding: 0 0 0 16px;
        position: absolute;

        .icon {
            font-size: 24px;
            margin: 0 24px -3px 0;
        }
    }

    .user-avatar {
        @include z-depth-1-half;

        align-items: center;
        background-color: #666;
        border-radius: 50%;
        color: #fff !important;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        position: absolute;
        right: 16px;
        text-decoration: none !important;
        top: 8px;
        width: 40px;
    }

    .user-initials {
        font-family: 'Roboto Condensed', Arial Narrow, sans-serif;
        font-size: 1.25rem;
        font-weight: 100;
    }
}
