

.mis-reports-page {
    .control-bar-component {
        > div {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
        }

        .form-field-component {
            //width: 200px;

            .form-error {
                display: none;
            }
        }

        .v-divider {
            text-align: center;
            width: 20px;
        }
    }

    .reports-chart-item {
        .list-item-footer-component {
            margin: 0;
            padding: 20px 20px 20px 0;
        }
    }
    
      .padded-card {
        padding:20px;
      }
    
}

      .legend {
        font-size: 12px;
        overflow:auto;
      } 
      
     .legend-div {
        font-size: 12px;
        overflow:auto;
      } 
      
      rect {  
        stroke-width: 2; 
      }
      
       #donut-chart {
        height: 360px;
        margin: 0 auto;
        position: relative;
        width: 360px;
      }
      .donut-tooltip {
        background: #eee;
        box-shadow: 0 0 5px #999999;
        color: #333;
        display: none;
        font-size: 12px;
        left: calc( 50% - 50px );
        padding: 10px;
        position: absolute;
        text-align: center;
        top: calc( 50% - 25px );
        width: 100px;
        z-index: 10;
      }
      

      
      rect {
        cursor: pointer;                                              /* NEW */
        stroke-width: 2;
      }
      rect.disabled {                                                 /* NEW */
        fill: transparent !important;                                 /* NEW */
      }                                                               /* NEW */
      h1 {                                                            /* NEW */
        font-size: 14px;                                              /* NEW */
        text-align: center;                                           /* NEW */
      }                                                               /* NEW */
      
      .line {
  fill: none;
  stroke-width: 3px;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.hover-line {
  stroke: #B74779;
  stroke-width: 2px;
  stroke-dasharray: 3,3;
}


.misreports-container {
    display: flex;


    .misreports-left {
        width: 35%;
    }
    
    .misreports-right {
        width: 65%;
    }
    
    .misreports-left-50 {
        width: 50%;
    }
    
    .misreports-right-50 {
        width: 50%;
    }

    .misreports-left, .misreports-left-50 {
        margin-right: 2em;
    }
}


@media only screen and (max-width: $mobile-breakpoint) {
    .misreports-container {
        flex-direction: column;

        .misreports-left,
        .misreports-right {
            width: 100%;
        }

        .misreports-left {
            margin-right: 0 !important;
        }
    }
}
      
.points {
background-color: red;
font-weight:bold;
}      
