

/*
===============
Old stuff (Material)
===============
*/
.illustration {
    display: inline-block;
    width: 300px;
    height: auto;
    stroke-width: 0;
}



.aspect-ratio {
    position: relative;
    display: block;
    width: 100%;

    // temp for testing
//    background-color: rgba(0,0,0,0.2);
}
.aspect-ratio-1-1 {
    padding-bottom: 100%;
}
.aspect-ratio > .illustration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 100%;
}


/*
===============
New stuff (IG React)
===============
*/



.illustration-wrapper {
    display: block;
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    svg {
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        stroke-width: 0;
        top: 0;
        width: 100%;
    }
}
