
// .app-website {
//     .jumbotron {
//         background: $ihouse-gradient-blue;
//     }
// }
// .app-turboleads {
//     .jumbotron {
//         background: $turboleads-gradient-red;
//     }
// }

.page-header {
    display: none;
    transition: 0.3s all ease-in-out;
    background-color: #eceeef;

    @media only screen and (min-width: $l_screen_md_min) {
        display: block;
    }

    .jumbotron {
        display: none;
        // padding: 3rem 2rem 1rem 2rem;
        padding: 42px 24px 14px 24px;
        margin-bottom: 0;
        border-radius: 0 !important;
        font-size: 16px;
        transition: 0.3s all ease-in-out;

        @media only screen and (min-width: $l_screen_md_min) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .left-side-jumbotron {
            flex: 1 0 auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
        }
        .jumbotron-heading {
            font-size: 2.5rem;
            transition: 0.3s all ease-in-out;
        }
        .jumbotron-message {
            margin-left: 16px;
        }

        .jumbotron-action {
            cursor: pointer;
            text-decoration: underline;
        }

        // h1 {
        //     color: #fff;
        // }
        .page-help {
            display: inline-block;
        }

        .action-icon,
        .action-text {
            a {
                color: #292b2c;
            }
        }

        .action-icons {
            display: flex;

            .action-icon {
                position: relative;
            }

            .dropdown-component {
                .dropdown-icon {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;

                    width: 48px;
                    height: 1*$units;

                    margin: 0;
                    padding: 0;
                    text-align: center;

                    @media only screen and (min-width: $l_screen_md_min) {
                        height: $l_header_height;
                        width: 1*$units;
                    }

                    .icon {
                        font-size: 24px;
                    }
                }
                .menu {
                    margin-top: 8px;
                }
            }
        }

        &.select-mode {
            color: #fff;
            background: #1d529a;

            a {
                color: #fff;
            }
        }

        &.search-mode {
            background: #fff;
            color: $body-color;
            border-bottom: 1px solid #eee;

            a {
                color: $body-color;
            }

            input[type="text"] {
                width: 100%;
                height: 60px;
                box-sizing: border-box;
                border: 0;
                font-size: 16px;
                padding: 0 16px;
            }
        }
    }
}


.sticky-outer-wrapper {
    .sticky-inner-wrapper {
        z-index: 999;
    }

    &.active {
        .sticky-inner-wrapper {
            .toolbar {
                box-shadow: none;
            }
            //z-index: 1000;
        }
        .page-header {
            background-color: #fff;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

            .jumbotron {
                left: 0;
                right: 0;
                padding: 0;
                border-top: 1px solid rgba(0,0,0,0.16);
                background-color: #fff;

                h1.jumbotron-heading {
                    display: block;
                    height: 60px;
                    padding: 0;
                    padding-left: 24px;
                    margin: 0;
                    font-size: 18px;
                    line-height: 60px;
                }

                .menu {
                    margin-right: 8px;
                }

                &.select-mode {
                    color: #fff;
                    background: #1d529a;

                    a {
                        color: #fff;
                    }
                }

                &.search-mode {
                    background: #fff;
                    color: $body-color;

                    a {
                        color: $body-color;
                    }

                    input[type="text"] {
                        width: 100%;
                        height: 60px;
                        box-sizing: border-box;
                        border: 0;
                        font-size: 16px;
                        padding: 0 16px;
                    }
                }

                .btn {
                    margin-right: 12px;
                }
            }
        }
    }
}
