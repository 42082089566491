
// TODO: file needs cleanup


.getting-started-count {
    position: absolute;
    top: 0;
    right: 0;

    display: block;
    height: 48px;
    padding: 0 12px 0 0;
    margin: 0;
    line-height: 48px;
    font-size: 1rem;
    font-weight: inherit;
}


.getting-started-list-item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    box-sizing: border-box;
    height: auto;
    padding: 0;
    background-color: #fff;
    opacity: 1;

    font-size: $list-item-font-size;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
}
.getting-started-list-item:last-child {
    border-bottom: 0;
}
.getting-started-list-item > * {
    padding-top: 8px;
    padding-bottom: 8px;
}
.getting-started-list-item .tile {
    width: 100%;
    height: auto;
}



.getting-started-list-item .list-item-main {
    padding-right: 12px;
}
.getting-started-list-item .list-item-footer {
    display: none;
    flex: 1 0 100%;
    padding: 0px 16px 8px 72px;
}
.list-item-description {
    display: none;
}
.getting-started-lead-capture-email {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}



/* active state */
.checklist-item.active .circle-front {
    background-color: rgb(156, 204, 160);
}
.getting-started-list-item.active .list-item-description {
    display: inline;
}
.getting-started-list-item.active .list-item-footer {
    display: flex;
}


/* selected (completed) state */
.getting-started-list-item.selected {
    border-bottom-color: #fff;
}
.selected .list-item-title {
    color: #aaa;
    text-decoration: line-through;
}

.simple-list-item, .task-list-item {
    border-bottom: 1px solid #eee;
}
