
// TODO: needs cleanup

.list-item.event-item {
    height: auto;
    min-height: 76px;
}
.event-tile {
    height: auto;
    min-height: 76px;
    align-items: flex-start;
}

.event-tile:before {
    content: '';
    position: absolute;
    top: 0;
    left: 36px;
    bottom: 0;

    display: block;
    width: 1px;
    height: auto;
    background-color: #efefef;
}
.list-item:first-child > .event-tile:before {
    top: 36px;
}
.list-item:last-child > .event-tile:before {
    bottom: auto;
    height: 36px;
}

.event-header {
    position: relative;
    padding: 0 40px 8px 0;
}
.event-body {
    padding: 8px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.065);
    @include small;
}
.event-actions {
    margin-top: 8px;
}


.event-header-action {
    @include icon-wrapper;
    color: #f34e00;
}
.event-header .event-header-action,
.event-header .more-menu-icon {
    position: absolute;
    top: 0;
    right: -8px;
}
.event-header .more-menu {
    top: 0;
    right: -8px;
}


.activity-event-title,
.email-event-subject {
    color: #373a3c;
}


/*
 * color code the different event types
 *
 * gray is default/system
 * green for initial lead capture/assignment
 * blue for communications
 * yellow for notes
 *
 */
/* lead capture, lead assignment?, website activity */
.created-event .circle-front,
.assigned-event .circle-front {
    background-color: #abdac0;
}

/* website activity: visiting, save search, save listing */
.activity-event .circle-front {
    background-color: #ffb0b0;
}

/* communications */
.call-event .circle-front,
.text-event .circle-front,
.email-event .circle-front {
    background-color: #a2d4e8;
}
/* notes */
.note-event .circle-front {
    background-color: #f5da4d;
}

/* inbound/outbound icons */
.event-header .inline-icon-small {
    display: inline-block;
//    color: #a2d4e8; // light blue
    color: #53a95b; // light green
    vertical-align: top;
    margin-top: 1px;
}
.inline-icon-small .icon {
    display: block;
    font-size: 16px;
    width: 16px;
    height: 16px;
}


/* email threads */
.email-thread-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
}
.email-thread-list-item:last-child {
    border: 0;
}

.email-thread-list-item > a {
    display: block;
    padding: 12px 0;

}
.email-thread-list-item-more {
    margin: -12px 0 12px 0;
}


/* show spinner while loading new timeline items */
.timeline-loading #contact-timeline-toggle {
    display: none;
}
.timeline-footer-loading {
    display: none;
}
.timeline-loading .timeline-footer-loading {
    display: block;
}

.card-footer.toggle-show-more,
.timeline-footer-loading {
    text-align: left !important;
}
