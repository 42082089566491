.benefits {
    background-color: #333;
    bottom: 0;
    color: #fff;
    height: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;

    .react-swipeable-view-container {
        height: 100%;
    }

    .benefits-item {
        align-items: center;
        display: flex;
        flex: 1 0 100%;
        flex-flow: column nowrap;
        height: 100%;
        justify-content: center;
        overflow: hidden;
        text-align: center;

        @media (orientation: landscape) {
            flex-flow: row nowrap;
        }

        &.item-follow-up {
            background: linear-gradient(to bottom, #e44c00, #d41d00);
        }

        &.item-easy {
            background-color: #0087ea;
        }

        &.item-measure {
            background-color: #444;
        }

        .benefits-illustration {
            display: flex;
            flex: 1 0 auto;
            flex-flow: column nowrap;
            justify-content: center;
            width: 100%;

            @media (orientation: landscape) {
                height: 100%;
                width: auto;
            }
        }

        .benefits-text {
            flex: none;
            height: 256px;
            padding: 24px;

            @media (orientation: landscape) {
                flex: 1 0 auto;
                max-width: 320px;
                text-align: left;
            }

            h2 {
                color: #fff;
                font-size: 24px;
                line-height: 32px;
            }

            p {
                color: rgba(255, 255, 255, 0.6);
                font-size: 15px;
                line-height: 24px;
            }
        }
    }

    .benefits-controls {
        bottom: 0;
        left: 50%;
        margin-left: -61px;
        position: absolute;
        text-align: center;
        width: 122px;

        @media (orientation: landscape) {
            bottom: 50%;
            left: auto;
            margin-left: 0;
            margin-bottom: -112px;
            right: 173px;
        }

        .btn {
            @include z-depth-2;

            color: #696969 !important;
            padding: 0 16px;
            width: 100%;
        }

        ul {
            display: block;
            font-size: 0;
            line-height: 0;
            list-style: none;
            margin: 0;
            padding: 24px 0;
            text-align: center;

            li {
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                height: 8px;
                list-style: none;
                margin: 0 4px;
                padding: 0;
                transition: 300ms ease-in-out;
                width: 8px;

                &.active {
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    &.open-exit {
        transition: 300ms;
    }

    &.open-exit {
        opacity: 1;

        &.open-exit-active {
            opacity: 0.01;
        }
    }
}
