

// TODO: de-duplicate circle and avatar?


// at some point we may want to break out tile into it's own file
.tile {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    height: 72px;
    padding: 8px;

    background-color: #fff;
//    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    opacity: 1;
}
.tile > * {
    padding: 0 8px;
}

.tile-pre {
    flex: none;
    width: 56px;
}
.tile-avatar {
    border-radius: 50%;
    @include responsive-img;
}
// .tile-initials,
// .circle-text {
//     font-family: 'Roboto Condensed', Arial Narrow, sans-serif;
//     font-size: 1.25rem;
//     font-weight: 100;
// }
//
// .circle-container {
//     position: relative;
//     perspective: 1000;
//
// }
//
//
//
// .circle-container,
// .circle-front,
// .circle-back {
//     width: 40px;
//     height: 40px;
//     background-color: rgba(0,0,0,0);
// }
// .circle {
//     transition: 0.5s;
//     transform-style: preserve-3d;
//     transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
// }
// .circle {
//     position: relative;
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// }
//
// .circle-front,
// .circle-back {
//     border-radius: 50%;
// //    box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
//     backface-visibility: hidden;
//
//     position: absolute;
//     top: 0;
//     left: 0;
//
//     display: flex;
//     flex-flow: row nowrap;
//     justify-content: center;
//     align-items: center;
//
//     color: #fff;
// }
// .circle-front {
//     background-color: #d2d2d2;
//     z-index: 2;
// }
// .circle-back {
//     transform: rotate3d(0,45,0,180deg);
//     background-color: rgba(0,0,0,0.25);
// }



.tile-main {
    flex: 1 1 auto;
    min-width: 0;
}
.tile-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.tile-extras {
    flex: none;
    width: 80px;
    text-align: right;
    font-size: 0;
}
.tile-actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    opacity: 1;
    transition: 0.3s all ease-in-out;
}
.tile-actions-tray {
    left: 0;
}
.tile-action,
.tile-action:visited {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;
    margin: 0 4px;

    font-size: 1rem;
//    color: #999;
    color: #f34e00;
    text-decoration: none;
    cursor: pointer;
}
.tile-action:hover,
.tile-action:active {
    color: #f34e00;
}


// three line variant
.list-item.three-line-list-item > .tile {
    height: 88px;
}


// disabled state
.tile-action.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: #999 !important;
}

// selected state
.list-item.selected > .tile {
    background-color: #efefef;
}
.list-item.selected .circle-container .circle,
.circle-container.checked .circle {
    transform: rotate3d(0, 45, 0, 180deg);
}

// deleted state
.list-item.deleted {
    opacity: 0;
    height: 0px !important;
    min-height: 0px !important;
    overflow: hidden;
}

.profile-completed-appear.profile-completed-appear-active {
    transform: rotate3d(0, 45, 0, 180deg);
}


.tile-main-content {

}
.tile-main-actions {

}
