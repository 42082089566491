

.tabs,
.tab {
    list-style: none;
    height: 48px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.tabs {
    flex: 1 0 100%;
    display: flex;
    flex-flow: row nowrap;
}
.equal-tabs {
    position: relative;
    height: 48px;
}
.scrollable-tabs {
    position: relative;
    flex: 1 0 100%;
    height: 48px;
    overflow: hidden;
}
.scrollable-tabs .tabs {
    position: absolute;
    top: 0;
    left: 60px;
}



.tab {
    flex: 0 1 auto;
}
.equal-tabs .tab {
    flex: 1 0 auto;
    text-align: center;
}
.tab a,
.tab span {
    display: block;
    padding: 0 12px;
    height: 48px;
    box-sizing: border-box;

    font-size: 14px;

    text-transform: uppercase;
    text-decoration: none !important;
    line-height: 48px;

    border-bottom: 2px solid rgba(255,255,255,0);
    transition: 0.3s border-color ease-in-out;

    cursor: pointer;
}

.scrollable-tabs .tab a,
.scrollable-tabs .tab span {
    border-bottom: 0;
}

//.equal-tabs .tab a,
//.equal-tabs .tab span {
//    text-align: center;
//}
.reverse .tab a,
.reverse .tab span {
    color: rgba(255,255,255,0.7);
}



.reverse .tab .hover,
.reverse .tab.active a,
.reverse .tab.active span {
    color: rgba(255,255,255,1);
    border-color: rgba(255,255,255,1);
}


.bubble {
    display: none;
}

.active-tab-line {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 20px;
    height: 2px;
}
.reverse .active-tab-line {
    background-color: #fff;
}


/* tab views are the content toggled by tabs */
.tab-view {
    display: none;
}
.tab-view.active {
    display: block;
}











.toolbar-tabs {
    overflow: hidden;
    max-width: 100%;
}

.tabs-component {
    position: relative;
    @include disable_text_selection;

    ul {
        display: flex;
        flex: 1 0 100%;
        flex-flow: row nowrap;
        list-style: none;
        margin: 0;
        padding: 0;
        @include disable_text_selection;

        li {
            box-sizing: border-box;
            color: rgba(255, 255, 255, 0.7);
            cursor: pointer;
            display: block;
            flex: 0 1 auto;
            font-size: 14px;
            height: 48px;
            line-height: 48px;
            padding: 0 12px;
            position: relative;
            text-transform: uppercase;
            white-space: nowrap;
            @include disable_text_selection;

            &.active {
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    .tab-line {
        background-color: #fff;
        bottom: 0;
        height: 2px;
        left: 0;
        line-height: 2px;
        position: absolute;
        transition: 300ms ease-in-out;
        width: 0;
    }
}



/* different color depending on what the app accent color is */
.page-header-tabs {
    padding-left: 20px;
    
    .tabs-component {
        ul {
            li {
                color: rgba(100, 100, 100, 0.7);

                &.active {
                    color: #00acfd;
                }
            }
        }
        .tab-line {
            background-color: #00acfd;
        }
    }
}
.sticky-outer-wrapper.active .page-header-tabs {
    padding-left: 14px;
}
