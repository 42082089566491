/* Breakpoints
 *
 * define common breakpoint variables
   ========================================================================== */

/* phone */
$bp-phone-portrait: 320px;
$bp-lt-phone-portrait: 319px;
$bp-gt-phone-portrait: 321px;
$bp-phone-landscape: 480px;
$bp-lt-phone-landscape: 479px;
$bp-gt-phone-landscape: 481px;

/* small tablet */
$bp-sm-tablet-portrait: 600px;
$bp-lt-sm-tablet-portrait: 599px;
$bp-gt-sm-tablet-portrait: 601px;
$bp-sm-tablet-landscape: 800px;
$bp-lt-sm-tablet-landscape: 799px;
$bp-gt-sm-tablet-landscape: 801px;

/* tablet */
$bp-tablet-portrait: 768px;
$bp-lt-tablet-portrait: 767px;
$bp-gt-tablet-portrait: 769px;
$bp-tablet-landscape: 1024px;
$bp-lt-tablet-landscape: 1023px;
$bp-gt-tablet-landscape: 1025px;

/* small desktop */
$bp-sm-desktop: 1080px;
$bp-lt-sm-desktop: 1079px;
$bp-gt-sm-desktop: 1081px;

/* standard desktop */
$bp-desktop: 1280px;
$bp-lt-desktop: 1279px;
$bp-gt-desktop: 1281px;

/* large desktop */
$bp-lg-desktop: 1600px;
$bp-lt-lg-desktop: 1599px;
$bp-gt-lg-desktop: 1601px;

/* extra large desktop */

$bp-xl-desktop: 2000px;
$bp-lt-xl-desktop: 1999px;
$bp-gt-xl-desktop: 2001px;

/*desktop and mobile breakpoint*/
$desktop-breakpoint: 992px;
$mobile-breakpoint: 991px;

