/*
 * responsive images?
 */

@mixin responsive-img {
    max-width: 100%;
    height: auto;
}

.w-1 {
    width: 1*$units !important;
}
.w-2 {
    width: 2*$units !important;
}
.w-3 {
    width: 3*$units !important;
}
.w-4 {
    width: 4*$units !important;
}
.w-5 {
    width: 5*$units !important;
}
.w-6 {
    width: 6*$units !important;
}
.w-7 {
    width: 7*$units !important;
}
.w-8 {
    width: 8*$units !important;
}

.z-depth-0 {
    box-shadow: none !important;
}

.z-depth-1-half {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.z-depth-1 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.z-depth-2 {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    z-index: 20;
}

.z-depth-3 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
    z-index: 30;
}

.z-depth-4 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
    z-index: 40;
}

.z-depth-5 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
    z-index: 50;
}

.hidden {
    display: none !important;
}

@mixin columns {
    color: #aaa;
    font-size: 13px;
}
.columns {
    @include columns;
}

@mixin small {
    color: #aaa;
    font-size: 13px;
}
.small {
    @include small;
}

@mixin text-overflow-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-overflow-ellipsis {
    @include text-overflow-ellipsis;
}

@mixin text-overflow-wrap {
    display: block;
    white-space: normal;
}
.text-overflow-wrap {
    @include text-overflow-ellipsis;
}


/* disable text selection */
@mixin disable-text-selection {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;  /* Chrome all / Safari all */
    -khtml-user-select: none !important;
    -moz-user-select: none !important;     /* Firefox all */
    -ms-user-select: none !important;      /* IE 10+ */
    user-select: none !important;          /* Future standardized version (not used yet) */
}
.disable-text-selection {
    @include disable-text-selection;
}


@mixin divider {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: 0;
    overflow: hidden;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,0.12);
}
.divider {
    @include divider;
}
.section-divider {
    @include divider;
    margin: 24px 0;
}



@mixin icon-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 24px;
    text-decoration: none !important;
    cursor: pointer;
}

@mixin placeholder {
    color: #aaa;
    padding-right: 12px;
}

.placeholder {
    @include placeholder;
}

@mixin bg-style ($background, $color) {
    background-color: $background;
    color: $color;

    &:hover {
        background-color: $background;
        color: $color;
    }
    a {
        color: $color;
        &:hover {
            color: $color;
        }
    }
}

.full-width {
    width: 100%;
}

.no-shadow {
    box-shadow: none;
}