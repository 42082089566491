


/* feedback page */
.feedback-select-field label {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: auto;
    height: 48px;
    line-height: 48px;
    padding-left: 12px;
}
.feedback-select-field span {
    padding-left: 48px;
}
.feedback-select-field span:after {
    right: 18px;
}
