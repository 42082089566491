.overlay {
    align-items: center;
    background-color: #444;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    margin: 0;
    opacity: 0.5;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9010;

    &.white-theme {
        background-color: #fff;
    }

    &.open-enter,
    &.open-exit {
        transition: 300ms;
    }

    &.open-enter {
        opacity: 0.01;

        &.open-enter-active {
            opacity: 0.5;
        }
    }

    &.open-exit {
        opacity: 0.5;

        &.open-exit-active {
            opacity: 0.01;
        }
    }

    .spinner {
        align-items: center;
        display: flex;
        flex: 1 0 100%;
    }
}
