/* ============ OLD STUFF ============= */


/*
 * form variables
 */
//$placeholder-text-color: #ccc;
$placeholder-text-color: #707070;
//$label-text-color: #aaa;
$label-text-color: #222;
$input-text-color: #222;


$dark-theme-placeholder-text-color: #fff;
$dark-theme-label-text-color: #fff;
$dark-theme-input-text-color: #fff;


/*
    ==========================================
    Resets/Defaults
    ==========================================
*/

/* placeholder text */
::-webkit-input-placeholder {
    color: $placeholder-text-color;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder-text-color;
}
::-moz-placeholder {  /* Firefox 19+ */
    color: $placeholder-text-color;
}
:-ms-input-placeholder {
    color: $placeholder-text-color;
}




/* no outlines */
input,
select,
textarea {
    outline: none !important;
}






/* mixin for base text input type styles */
@mixin text-input-default {
    box-sizing: border-box;
    width: 100%;
    height: 48px;


    padding: 0;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-radius: 0;

    font-size: 16px;
    line-height: 48px;
    color: $input-text-color;

    background-color: transparent;
    background-clip: padding-box;
}
.text-input-default {
    @include text-input-default;
}



/* text inputs */
// this approach is a bit too invasive.
// instead using class name on the .field to determine formatting like
// .text-field input { .text-input-default; }
// input[type=text],
// input[type=password],
// input[type=email],
// input[type=url],
// input[type=time],
// input[type=date],
// input[type=datetime],
// input[type=datetime-local],
// input[type=tel],
// input[type=number],
// input[type=search] {
// //    .text-input-default;
// }





/*
 * Set step attribute to zero to hide the crappy looking spinner.
 * Do this for places where you want an number only keyboard but no increment/decrement UI
 * e.g. zip, confirmation code, etc.
 */
input[type=number][step='0']::-webkit-inner-spin-button,
input[type=number][step='0']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}





.field-spacer {
    padding-top: 8px;
}


/*
    ==========================================
    Fields:
    ==========================================
*/
/*
 * Every input must be wrapped in a div with className field
 * It is leveraged for both formatting and for scripts that enhance UI.
 */
.field {
    position: relative;
}




.text-field input {
    @include text-input-default;
}



/*
 * focus state
 */
.field.has-focus input {
    border-bottom-width: 2px;
    border-color: #008eff;
}

/*
 * error state
 */
.field.has-error input {
    border-bottom-width: 2px;
    border-color: #dd2c00;
}

label.small {
    margin-bottom: 0;
    font-size: 12px;
}



/*
 * A floating label field starts with the label styled like a placeholder value.
 * When the input is focused and/or when it has a value in it,
 * the label shrinks and moves back to a more traditional position above the input
 * so you still have context for the value you are entering / have entered.
 */
.floating-label-field {
    box-sizing: border-box;
    padding-top: 28px;
}
.floating-label-field label {
    position: absolute;
    top: 39px;
    left: 0;
    font-size: 16px;
    color: #888;
    transition: 0.3s all ease-in-out;
}

/* has focus/value state: */
.floating-label-field.has-focus label,
.floating-label-field.has-value label {
    top: 12px;
    font-size: 12px;
    color: #48aeff;
}


/* error state */
.floating-label-field.has-focus.has-error label,
.floating-label-field.has-value.has-error label {
    color: #dd2c00;
}


/*
 *
 * Formatting Options:
 * The following class names get added to fields to specify special formatting options
 *
 */

.single-line-text-field input {
    width: 100%;
    height: 56px;
    padding: 20px 12px;
    border: 0;
}


.multi-line-text-field textarea {
    width: 100%;
    min-height: 168px;
    padding: 20px 12px;
    border: 0;
    resize: none;
}


/* inline label */
.inline-label-field label {
    position: absolute;
    top: 1.125rem;
    left: 12px;
    font-size: 0.875rem;
}
.inline-label-field input {
    padding-left: 72px;
}



/*
 * fake select field (on the compose view)
 */
.fake-select-field {
    padding: 16px 12px;
    cursor: pointer;
}
.fake-select-field label {
    cursor: pointer;
}
.fake-select-field-value {
    display: block;
    margin: 0;
    cursor: pointer;
}
.select-field-arrow {
    @include icon-wrapper;

    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -24px;
}



/*
 * select fields
 */

select[data-uk-select] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    border: 0;

    opacity: 0;
}
.floating-label-field select[data-uk-select] {
    top: 28px;
}


.select-field {
    position: relative;
    display: block;
    min-height: 48px; // keep it from jumping around while span gets created
}
.select-field.floating-label-field {
    min-height: 76px; // keep it from jumping around while span gets created
}

.inline-select-field {
    position: relative;
    display: inline-block;
    min-height: 48px; // keep it from jumping around while span gets created
}
.select-field > span,
.inline-select-field > span {
    display: block;
    @include text-input-default;
    padding-right: 24px;
}
// truncate values that are too long
.select-field > span {
    @include text-overflow-ellipsis;
}

.select-field > span:after,
.inline-select-field > span:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 6px;
    bottom: 0;
    display: block;
    font-size: 1rem;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #888;
}
.select-field.has-focus > span,
.inline-select-field.has-focus > span {
    border-bottom-width: 2px;
    border-color: #008eff;
}


.select-field.floating-label-field > span:after {
    margin-top: 11px;
}
.inline-select-field.short {
    margin-left: 3px;
    margin-right: 3px;
}

.select-field.with-label {
    margin-top: 20px;
}
.select-field.with-label label {
    position: absolute;
    top: -14px;
}
.select-field.with-label.has-focus label {
    color: #48aeff;
}
.with-label label {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 12px;
}



/* growing textarea */
.text-field textarea {
    @include text-input-default;
}
.text-field .textarea {
    position: relative;
}
.text-field .textarea span {
    @include text-input-default;
    display: block;
    height: auto;
    min-height: 48px;
    padding: 12px 0;
    border: 0;
    line-height: 1.25;
    color: rgba(255,255,255,0);
}
.text-field .textarea textarea {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: auto;
    padding: 12px 0;
    resize: none;

    line-height: 1.25;
    background-color: transparent;
}

/*
 * focus state
 */
.field.has-focus .textarea textarea {
    border-bottom-width: 2px;
    border-color: #008eff;
}
/*
 * error state
 */
.field.has-error .textarea textarea {
    border-bottom-width: 2px;
    border-color: #dd2c00;
}






//.switch-field {
//    height: 72px;
//    padding: 12px 0;
//    box-sizing: border-box;
//}
//.switch-field .switch {
//    position: absolute;
//    top: 8px;
//    right: 0;
//}



.switch-field label {
    color: $input-text-color;
}



label {
    color: $label-text-color;
}


/*
 * Error messages
 */

.error {
    display: block;
    padding: 0;

    font-size: 12px;
    color: #dd2c00;

    background: none;
}



/*
 * Dark theme modifications
 */
.dark-theme {

    /* placeholder text */
    ::-webkit-input-placeholder {
       color: $dark-theme-placeholder-text-color;
    }
    :-moz-placeholder { /* Firefox 18- */
       color: $dark-theme-placeholder-text-color;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
       color: $dark-theme-placeholder-text-color;
    }
    :-ms-input-placeholder {
       color: $dark-theme-placeholder-text-color;
    }


    .text-field input {
        color: $dark-theme-input-text-color;
        border-bottom-color: rgba(255,255,255,.5);
    }
    .text-field label {
        color: $dark-theme-label-text-color;
    }

    .floating-label-field.has-focus label,
    .floating-label-field.has-value label {
        color: red;
    }
    .floating-label-field.has-focus input,
    .floating-label-field.has-value input {
        border-bottom-color: #fff;
    }

    /* override stupid chrome autocomplete yellow color! */
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #b32900 inset;
        -webkit-text-fill-color: #fff !important;
    }
}



/*
 * fieldsets
 */
.fieldset-flex {
    display: flex;
    flex-flow: row nowrap;
}
.fieldset-fields {
    flex: 1 1 auto;
}
.fieldset-pre,
.fieldset-action {
    flex: none;
    width: 72px;
}
.fieldset-pre {
    padding-right: 24px;
    color: $label-text-color;
}
.fieldset-action {
    padding-left: 24px;
    text-align: right;
    color: $label-text-color;
}

.fieldset-icon {
    @include icon-wrapper;
    cursor: default;
}


.email-fieldset .fieldset-action {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

/* date type fields not playing nice with flexbox so need a max-width imposed. */
.text-field input.flatpickr {
    max-width: 216px;
}


/*
 * Button Groups (radios and checkboxes)
 */
$button-group-border-radius: 3px;
$button-group-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

.checkbox-button-group,
.radio-button-group {
    display: inline-block;
    width: auto;
    margin: 4px 0;
    font-size: 0;
    border-radius: $button-group-border-radius;

    @include z-depth-1;
    box-shadow: $button-group-shadow;
    background-color: #fff;
}
.checkbox-button,
.radio-button {
    display: inline-block;
}
.checkbox-button input[type="checkbox"],
.radio-button input[type="radio"] {
    display: none;
}
.checkbox-button label,
.radio-button label {
    display: block;
    box-sizing: border-box;
    min-width: 64px;
    height: 36px;
    padding: 0 12px;
    margin: 0;
    border: 0;
    border-right: 1px solid rgba(0,0,0,0.08);
    border-radius: 0;

    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;

    text-transform: uppercase;
    text-align: center;
    line-height: 36px;
    text-decoration: none !important;

    cursor: pointer;
    color: $label-text-color;
    background-color: #fff;


    @media all and (min-width: $bp-gt-phone-portrait) {
        padding: 0 16px;
    }
}
.checkbox-button:first-child label,
.radio-button:first-child label {
    border-radius: $button-group-border-radius 0 0 $button-group-border-radius;
    margin-left: 0;
}
.checkbox-button:last-child label,
.radio-button:last-child label {
    border-radius: 0 $button-group-border-radius $button-group-border-radius 0;
}
.checkbox-button input[type="checkbox"]:checked + label,
.radio-button input[type="radio"]:checked + label {
    background-color: #bbb;
    color: #444;
}

$input-text-color: #222;
$label-text-color: #ccc;
$placeholder-text-color: #aaa;

/* ============  NEW STUFF =============*/

::-webkit-input-placeholder {
    color: $placeholder-text-color;
}

:-moz-placeholder {
    color: $placeholder-text-color;
}

::-moz-placeholder {
    color: $placeholder-text-color;
}

:-ms-input-placeholder {
    color: $placeholder-text-color;
}

.form-component {
    position: relative;

    .hidden-submit {
        height: 1px;
        left: -9999px;
        position: absolute;
        width: 1px;
    }

    .form-tip {
        @include small;

        padding-top: 16px;
    }

    .form-buttons {
        .btn {
            margin: 24px 12px 0 0;
        }
    }
}

.form-fieldset-component {
    display: flex;
    flex-flow: row nowrap;

    .form-fieldset-body {
        flex: 1 1 auto;
        min-width: 0;
    }

    .form-fieldset-prefix {
        color: $placeholder-text-color;
        flex: none;
        padding-top: 12px;
        width: 40px;
    }

    &.has-label {
        .form-fieldset-prefix {
            padding-top: 36px;
        }
    }
}

.form-group-component {
    display: flex;
    flex-flow: row nowrap;

    .form-group-body {
        flex: 1 1 auto;
        min-width: 0;
    }

    .form-group-suffix {
        color: #666;
        flex: none;
        width: 40px;

        .action-icon {
            padding-top: 4px;

            .action-button {
                width: 40px;
                height: 40px;
                color: #666 !important;
            }
        }
    }
}

@mixin select-field-component {
    > .form-field-input {
        @include text-overflow-ellipsis;

        padding-right: 32px;

        &:after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #888;
            content: "";
            display: block;
            font-size: 1rem;
            height: 0;
            position: absolute;
            right: 6px;
            top: 24px;
            width: 0;
        }
    }

    select {
        border: 0;
        height: 48px;
        left: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.form-field-component {
    position: relative;


    label {
        color: $label-text-color;
        font-size: 12px;
        margin: 0;
    }

    .placeholder {
        @include placeholder;
    }

    .form-helper {
        color: $placeholder-text-color;
        font-size: 12px;
        line-height: 12px;
        margin-top: 2px;
        min-height: 12px;
    }

    .form-error {
        color: #dd2c00;
        font-size: 12px;
        line-height: 12px;
        margin-top: 2px;
        min-height: 12px;
    }

    .hint-container {
        position: absolute;
        top: 0;
        right: 0;

        .hint-icon {
            height: 24px;
            justify-content: flex-end;
        }
    }

    .visibility-toggle {
        @include icon-wrapper;

        position: absolute;
        right: 0;
        top: 0;

        .icon {
            display: block;
        }
    }

    > .form-field-input {
        background-clip: padding-box;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(0, 0 ,0, .125);
        border-radius: 0;
        box-sizing: border-box;
        color: $input-text-color;
        display: block;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;

        .select-value {
            @include text-overflow-ellipsis;

            padding-right: 32px;
            position: relative;
            z-index: 1;
        }

        .select-menu {
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            cursor: pointer;
            display: block;
            max-height: 240px;
            max-width: 100%;
            min-width: 164px;
            overflow-y: auto;
            position: absolute;
            top: 5px;
            z-index: 101;
        }
    }

    &.muted {
        > .form-field-input {
            color: #ccc;

            &:after {
                display: none !important;
            }
        }
    }

  //.visibility-toggle {
    //    @include icon-wrapper;
    //
    //    position: absolute;
    //    right: 0;
    //    top: 0;
    //
    //    .icon {
    //        display: block;
    //    }
    //}


    &.small-size {
        width: $units * 3;
    }

    &.has-focus {
        > .form-field-input {
            border-color: #008eff;
            border-bottom-width: 2px;
        }
    }

    &.has-error {
        > .form-field-input {
            border-color: #dd2c00;
            border-bottom-width: 2px;
        }
    }

    &.checkbox-field-component {
        min-height: 48px;

        .form-field-input {
            border-bottom: none;
            position: relative;

            .box {
                border: 2px solid #ccc;
                border-radius: 2px;
                box-sizing: border-box;
                display: block;
                height: 20px;
                left: 16px;
                position: absolute;
                top: 14px;
                transition: 300ms ease-in-out;
                width: 20px;

                &:after {
                    border: 2px solid transparent;
                    border-left: 0;
                    border-top: 0;
                    box-sizing: border-box;
                    content: "";
                    display: table;
                    height: 13px;
                    left: 5px;
                    position: absolute;
                    top: 0;
                    transform: rotate(45deg);
                    transition: 300ms ease-in-out;
                    width: 7px;
                }
            }

            .box-label {
                @include text-overflow-ellipsis;

                cursor: pointer;
                padding: 0 20px 0 50px;
            }

            &.checked {
                .box {
                    background-color: #26a654;
                    border-color: #26a654;

                    &:after {
                        border-color: rgba(255, 255, 255, 0.87);
                    }
                }
            }
        }
    }

    &.auto-complete-field-component {
        .form-error {
            margin-top: 6px;
        }
    }

    &.multi-select-table-field-component {
        width: 240px;

        .table {
            max-height: 470px;
            overflow-y: auto;
            border: 1px solid #ccc;

            > li {
                transition: none;

                .icon-checkbox {
                    color: #ccc;
                }

                .icon-checkbox-checked {
                    color: #26a654;
                }

                &.header-row {
                    color: #222;
                    height: 52px;
                    border-bottom: 1px solid #ccc;

                    .table-header {
                        font-size: 15px;
                        @include text-overflow-ellipsis;
                    }
                }

                &.body-row, &.empty-row {
                    color: #222;
                    border-bottom: none;
                    font-size: 15px;

                    .table-data {
                        padding-top: 14px;
                       @include text-overflow-ellipsis;
                    }
                }
            }
        }
    }

    &.multi-select-field-component {
        @include select-field-component;

        > .form-field-input {
            overflow: visible;
            padding-right: 0;
            outline: none;

            .select-value {
                @include text-overflow-ellipsis;

                height: 100%;
                padding-right: 32px;
                position: relative;
                z-index: 1;
            }

            .select-menu {
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
                box-sizing: border-box;
                cursor: pointer;
                display: block;
                max-height: 240px;
                max-width: 100%;
                min-width: 164px;
                overflow-y: auto;
                position: absolute;
                top: 5px;
                z-index: 101;

                .checkbox-field-component {
                    .form-error {
                        height: 0;
                        margin: 0;
                    }

                    .form-field-input {
                        &:hover {
                            background-color: #efefef;
                        }

                        .box {
                            left: 16px;
                        }

                        .box-label {
                            padding: 0 20px 0 50px;
                        }
                    }
                }

                .customize-item {
                    padding-left: 16px;

                    &:hover {
                        background-color: #efefef;
                    }
                }
            }
        }
    }

    &.radio-select-field-component {
        @include select-field-component;

        > .form-field-input {
            overflow: visible;
            cursor: pointer;
            padding-right: 0;
            &:focus {
                outline: 0;
            }

            .select-value {
                padding-right: 32px;
            }

            .select-menu {
                padding: 8px 0;
                max-width: none;

                .form-field-component {
                    ul {
                        li {
                            &.create-new-item {
                                height: auto;
                                text-align: center;
                                color: #0275d8 !important;
                                padding: 8px 0 !important;
                                margin-bottom: -8px;
                                display: flex;
                                justify-content: center;

                                &:hover {
                                    button {
                                        background-color: #efefef;
                                    }
                                }

                                button {
                                    transition: none;
                                }
                            }

                            &.divider {
                                padding: 0;
                            }

                            padding: 12px 24px 12px 16px;

                            &:hover {
                                background-color: #efefef;
                            }
                        }
                    }
                }
            }
        }

        &.header-radio {
            @media only screen and (max-width: $bp-gt-sm-tablet-landscape) {
                > .form-field-input {
                    margin-right: 12px;

                    .select-value {
                        max-width: 150px !important;
                        padding-right: 22px;
                    }

                    .select-menu {
                        .radio-select-labels {
                            .main-label, .sub-label {
                                max-width: 200px !important;
                            }
                        }
                    }
                }
            }

            > .form-field-input {
                border-bottom: none;

                &:after {
                    top: 22px !important;
                }

                .select-value {
                    font-size: 13px;
                    color: #707070;
                    max-width: 330px;
                }

                .select-menu {
                    left: auto;
                    right: 0;

                    .radio-select-labels {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &:hover {
                            background-color: #efefef;
                        }

                        .main-label, .sub-label {
                            @include text-overflow-ellipsis;
                            max-width: 400px;
                            line-height: 12px;
                            height: 18px;
                            margin-left: 5px;
                        }

                        .main-label {
                            color: rgb(41, 43, 44);
                            font-size: 15px;
                        }

                        .sub-label {
                            color: $label-text-color;
                            font-size: 13px;
                        }
                    }
                }
            }

            .form-error {
                display: none;
            }
        }
    }

    &.select-field-component {
        @include select-field-component;
    }

    &.list-options-field-component {
        @include select-field-component;
        outline: 0;

        padding-top: 10px;

        label {
            left: 0;
            position: absolute;
            top: 0;
        }

        > .form-field-input {
            cursor: default;
        }
    }


    &.textarea-field-component {
        .form-field-input {
            line-height: 1.25;
            padding: 20px 0 12px 0;
            resize: none;
        }

        &.has-focus {
            .form-field-input {
                padding-bottom: 11px;
            }
        }
    }

    &.radio-field-component {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                @include text-overflow-ellipsis;

                color: #444;
                cursor: pointer;
                padding: 16px 0;
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .icon {
                    fill: #ccc;
                    margin-right: 8px;
                    margin-top: -2px;
                }

                &.checked {
                    .icon {
                        fill: #26a654;
                    }
                }
            }
        }
    }

    &.read-only-field-component {
        > .form-field-input {
            @include text-overflow-ellipsis;
        }
    }

    &.button-field-component {
        label {
            display: block;
        }

        ul {
            border: 1px solid rgba(0, 0 ,0, .125);
            border-radius: 3px;
            display: inline-block;
            list-style: none;
            margin: 8px 0 0 0;
            overflow: hidden;
            padding: 0;

            li {
                display: inline-block;

                .btn {
                    background-color: #fff;
                    border-radius: 0;
                    border-right: 1px solid rgba(0, 0 ,0, .125);
                    color: $label-text-color !important;
                    outline: none;
                    padding: 0 12px;
                    text-shadow: none;

                    @media all and (min-width: $bp-gt-phone-portrait) {
                        padding: 0 16px;
                    }
                }

                &:last-child {
                    .btn {
                        border: none;
                    }
                }

                &.checked {
                    .btn {
                        background-color: #bbb;
                        color: #444 !important;
                    }
                }
            }
        }
    }

    &.switch-field-component {
        > .form-field-input {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            span {
                background-color: #b0afaf;
                border-radius: 15px;
                content: "";
                display: inline-block;
                height: 15px;
                margin: 0 4px;
                position: relative;
                transition: background 300ms ease-in-out;
                width: 32px;

                &:after {
                    background-color: #f5f5f5;
                    border-radius: 21px;
                    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
                    content: "";
                    display: inline-block;
                    height: 21px;
                    left: -5px;
                    position: absolute;
                    transition: left 300ms ease-in-out, background 300ms ease-in-out, box-shadow 100ms ease-in-out;
                    top: -3px;
                    width: 21px;
                }

                &:hover {
                    background-color: #939393;

                    &:after {
                        background-color: #fff;
                    }
                }
            }

            &.checked {
                span {
                    background-color: desaturate(lighten(#26a654, 30%), 30%);

                    &:after {
                        background-color: #26a654;
                        left: 16px;
                    }
                }
            }
        }
    }

    &.interval-field-component {
        .form-field-component {
            .form-error {
                display: none;
            }
        }

        .interval-field-group {
            display: flex;

            .text-field-component {
                padding-right: 8px;
                width: 1 * $units;
            }

            .select-field-component {
                width: 2 * $units;
            }
        }
    }
}


