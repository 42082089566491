/*
    _modals: styles for modal dialogs like alert, confirm, prompt, etc.
*/


$modal-border-radius: 2px;


body.has-open-modal {
    overflow: hidden;
}
.ih-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9011; /* calculate this? */

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    visibility: hidden;
    opacity: 0;

    font-family: Helevetica, Arial, sans-serif;
    font-size: 16px;
    color: #666;
    line-height: 1.25;

    transition: all 0.3s ease-in-out;

    @media all and (min-width: $bp-tablet-portrait) {
        position: fixed;
    }
}
.ih-modal.open {
    visibility: visible;
    opacity: 1;
    overflow: auto;
}
.ih-modal-dialog {
    position: relative;
    width: auto;
    padding: 10px;
    margin: 0 auto;
    box-sizing: border-box;

    transform: translate(0, -25%);
    transition: all 0.3s ease-in-out;

    @media all and (min-width: $bp-tablet-portrait) {
        right: auto;
        left: 50%;
        width: 600px;
        margin-bottom: 30px;
        margin-left: -300px;
    }
    @media all and (min-width: $bp-tablet-portrait) and (min-height: $bp-sm-tablet-portrait) {
        // Don't set padding-top on this.
        // It interferes with click-out to close functionality.
        margin-top: 50px;
    }
    @media all and (min-width: $bp-tablet-portrait) and (min-height: $bp-tablet-portrait) {
        // Don't set padding-top on this.
        // It interferes with click-out to close functionality.
        margin-top: 100px;
    }
}

.ih-modal.open .ih-modal-dialog {
    transform: translate(0, 0);
}
.ih-modal-spinner .ih-modal-dialog {
    text-align: center;
}
.ih-modal-content {
    position: relative;
    border-radius: $modal-border-radius;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    outline: 0;
    background-clip: padding-box;
}
.ih-modal-flash .ih-modal-content {
    display: inline-block;
    background-color: rgba(60, 150, 60, 0.85);
    box-shadow: none;
    border-radius: 32px;
    color: #fff;
    text-align: left;
}
.ih-modal-spinner .ih-modal-content {
    display: inline-block;
    background-color: rgba(30, 30, 30, 0.85);
    box-shadow: none;
    border-radius: 32px;
    color: #fff;
    text-align: left;
}
.ih-modal-header {
    position: relative;
    padding: 20px 24px 0 24px;
    border-radius: $modal-border-radius $modal-border-radius 0 0;
}
h4.ih-modal-title {
    display: block;
    font-family: 'Roboto', Arial, sans-serif;
    padding: 0;
    margin: 0;
    font-size: 20px;
//    font-weight: 700;
    font-weight: 500;
    font-style: normal;
    color: #222;
}
.ih-modal-body {
    position: relative;
    padding: 24px 36px 24px 24px;
}
.ih-modal-body a {
    color: #0066cc;
}
.with-title .ih-modal-body {
    padding-top: 12px;
}
.ih-modal-message {
    position: relative;
}
.ih-modal-flash .ih-modal-message,
.ih-modal-spinner .ih-modal-message {
    padding-left: 34px;
}
.ih-modal-flash-icon {
    position: absolute;
    top: -4px;
    left: -2px;
    font-size: 28px;
}
.ih-modal-spinner-icon {
    position: absolute;
    top: -4px;
    left: -2px;

    box-sizing: content-box;
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    border-left-color: rgba(255,255,255,0.5);
    width: 20px;
    height: 20px;
    border-radius: 20px;
}
.ih-modal-close-x {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;

    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    color: #bbb;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    text-shadow: none;
    min-width: unset;
}
.ih-modal-close-x:hover {
    color: #666;
}
.ih-modal-footer {
    padding: 10px 10px 0 10px;
    border-top: 1px solid #efefef;
    text-align: right;
}


.ih-modal-button {
    display: inline-block;
    width: auto;
    padding: 0;
    padding-bottom: 3px;
    margin: 0 0 10px 6px;
    border: 0;
//    border-radius: 4px;
    box-sizing: border-box;
    overflow: visible; /* removes extra side padding in IE7 */
    outline: none;

    text-decoration: none;

    background-color: #666;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(100, 100, 100, 0.33);
    transition: all .2s linear;
}

.ih-modal-button > span {
    display: block;
    width: auto;
    height: 40px;
    padding: 0 24px;
    margin: 0;
    border: 0;
//    border-radius: 4px;
    box-sizing: border-box;

    font-family: 'Roboto', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    white-space: nowrap;
    line-height: 40px;
    text-shadow: 0 1px 3px rgba(0,0,0,0.5);
    background-color: #7b7b7b;
    transition: all .2s linear;
}


/* cleanup */
/* prevent collision with anchor styles */
a.ih-modal-button:link,
a.ih-modal-button:visited {
    text-decoration: none;
    color: #fff
}
/* remove extra padding in Firefox */
.ih-modal-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}


/* hover state */
.ih-modal-button:hover,
a.ih-modal-button:hover {
    text-decoration: none;
}
/* focus state */
.ih-modal-button:focus {
    box-shadow: 0 0 5px rgba(0, 143, 255, 1);
}


/* button type/color variations */
.ih-modal-button-primary {
    background-color: #0086C5;
}
.ih-modal-button-primary > span {
    background-color: #00ACFD;
}
.ih-modal-button-cancel {
    background-color: #777;
}
.ih-modal-button-cancel > span {
    background-color: #999;
}
/* special variant for "delete" button */
.ih-modal-confirm-delete .ih-modal-button-primary {
    background-color: #BD1111;
}
.ih-modal-confirm-delete .ih-modal-button-primary > span {
    background-color: #ED3D2D;
}
.ih-modal-button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}


.ih-modal .field,
.ih-modal-field {
    margin: 16px 0;
}

@mixin ih-modal-input-default {
    width: 100%;
    height: 36px;
    padding: 4px 8px;
    margin: 6px 0;
    border: 1px solid #aaa;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    outline: none;
    background-color: #f9f9f9;
    box-shadow: inset 1px 1px 3px rgba(0,0,0,0.2);

    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.ih-modal-input-default {
    @include ih-modal-input-default;
}


.ih-modal-input-text {
    @include ih-modal-input-default;

    @media (min-width: $bp-gt-phone-landscape) {
        max-width: 300px;
    }
}
.ie8 .ih-modal-input-text {
    max-width: 300px;
}
.ih-modal-input-text:focus {
    border: 1px solid rgba(0, 143, 255, 1);
    box-shadow: inset 1px 1px 3px rgba(0,0,0,0.2), 0 0 5px rgba(0, 143, 255, 1);
}

.ih-modal-input-textarea {
    @include ih-modal-input-default;

    height: 5em;
    max-width: 30em;
}



/* form validation errors */
.ih-modal-field input[type=text].ih-has-error,
.ih-modal-field input[type=password].ih-has-error,
.ih-modal-field input[type=email].ih-has-error,
.ih-modal-field input[type=number].ih-has-error,
.ih-modal-field input[type=search].ih-has-error,
.ih-modal-field input[type=tel].ih-has-error,
.ih-modal-field input[type=url].ih-has-error,
.ih-modal-field textarea.ih-has-error {
    border:1px solid #ff0000;
}
.ih-modal-field input[type=text].ih-has-error:focus,
.ih-modal-field input[type=password].ih-has-error:focus,
.ih-modal-field input[type=email].ih-has-error:focus,
.ih-modal-field input[type=number].ih-has-error:focus,
.ih-modal-field input[type=search].ih-has-error:focus,
.ih-modal-field input[type=tel].ih-has-error:focus,
.ih-modal-field input[type=url].ih-has-error:focus,
.ih-modal-field textarea.ih-has-error:focus {
    border: 1px solid #ff0000;
    box-shadow: inset 1px 1px 3px rgba(0,0,0,0.2), 0 0 5px #ff2200;
}

.ih-modal .ih-error {
    display: block;
    min-height: 16px;
    padding: 0;
    margin-top: 2px;
    color: #ff0000;
    background: none;
}


.ih-modal-field .ih-required {
    color: #ff0000;
}





.ih-modal-fieldset {
    padding: 0 10px;
    margin: 16px 0;
    background: #fff;
    border: 1px solid #ddd;
}
.ih-modal-legend {
    font-size: 14px;
    color: #bbb;
}
.ih-label-hint {
    font-style: italic;
    color: #bbb;
    font-size: 14px;
}




.ih-modal-mask,
.ih-page-mask,
.ih-white-page-mask,
.ih-invisible-page_mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9010;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    visibility: hidden;
    background-color: #444;

    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.ih-white-page-mask {
    background-color: transparent;
}

.ih-modal-mask.open,
.ih-page-mask.ih-page-mask-open,
.ih-white-page-mask.ih-white-page-mask-open {
    visibility: visible;
    opacity: 0.5;
}




/*
 * There are multiple queues now for modals.
 * One modal for each queue can be open at a time.
 * The modals will have a css class attached representing the queue namespace.
 * You can create and use your own modal namespaces and assign their layering order here.
 *
 * "general" is the default queue.
 * "priority" is another queue that gets used automatically in certain situations, and should represent the highest layer possible
 *
 */
.ih-modal-queue-general {
    z-index: 9011 !important;
}
.ih-modal-queue-priority {
    z-index: 9999 !important;
}






/* Modal Page/Forms - these larger modals slide in from the side */
.ih-modal-page .ih-modal-dialog {
    transform: translate(0, 0);
    left: -50%;
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;

    @media all and (min-width: $bp-tablet-portrait) {
        width: 98%;
    }
}
.ih-modal-page.open .ih-modal-dialog {
    left: 0;
}
.ih-modal-page .ih-modal-content {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-content: stretch;
    border-radius: 0;
}
.ih-modal-page .ih-modal-header {
    flex: none;
}
.ih-modal-page .ih-modal-body {
    overflow-y: auto;
    flex: 0 1 100%;
}
.ih-modal-page .ih-modal-footer {
    flex: none;
}
.ih-modal.posting .ih-modal-body,
.ih-modal.posting .ih-modal-footer,
.ih-modal-form.posting .ih-modal-body,
.ih-modal-form.posting .ih-modal-footer {
    opacity: 0.5;
}












.ih-simple-modal .ih-modal-body,
.ih-simple-modal-large .ih-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 12px;
    padding-bottom: 12px;
}
.ih-simple-modal.with-title .ih-modal-body,
.ih-simple-modal-large.with-title .ih-modal-body {
    padding-top: 0;
    padding-bottom: 12px;
}



.ih-simple-modal .ih-modal-dialog {
    right: auto;
    left: 50%;

    margin-top: 40px;
    margin-bottom: 40px;

    width: 300px;
    margin-left: -150px;

    @media all and (min-width: $bp-tablet-portrait) {
        width: 300px;
        margin-left: -150px;
    }
}


.ih-modal-tooltip {
    padding: 0 24px 24px 24px;
    font-size: 14px;
    color: #666;
}






.ih-bottom-sheet-modal .ih-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
}

.ih-bottom-sheet-modal .ih-modal-dialog {
    position: absolute;
    top: auto;
    bottom: 0;
    padding: 0;

    transform: translate(0, 25%);

//    right: auto;
//    left: 50%;
//    padding-bottom: 30px;
//
//    width: 300px;
//    margin-left: -150px;

    @media all and (min-width: $bp-tablet-portrait) {
        width: 600px;
        margin-left: -300px;
    }
}
.ih-bottom-sheet-modal.open .ih-modal-dialog {
    transform: translate(0, 0);
}
.ih-bottom-sheet-modal .ih-modal-content {
    border-radius: 0;
}





.ih-feature-discovery-modal {
    overflow: hidden !important;
    color: rgba(255,255,255,0.66);

    .ih-modal-dialog {
        width: 100%;
        max-width: 380px;
        left: 0;
        margin-top: 0 !important;
        margin-left: 0;
        padding: 0;
        transform: translate(0, 0);

        .ih-modal-content {
//            padding: 0 16px;
            max-width: 380px;
            padding: 0;
            background-color: transparent;
//            background-color: rgba(0,0,0,0.96);
            box-shadow: none;



            .ih-modal-header {
//                padding: 20px 24px;
                padding: 20px 40px;
                border-radius: 0;

                h4.ih-modal-title {
                    color: #fff;
                }
            }
            .ih-modal-body {
//                padding: 0 24px;
                padding: 0 40px;
            }
        }
    }
}
.ih-feature-discovery-modal.is-right .ih-modal-dialog {
    left: auto;
    right: 0;
    margin-left: auto;
    margin-right: 0;
}

.ih-modal-tap-target-centerpoint {
    position: absolute;
    // top and left will be calculated and styled inline

    display: flex;
    justify-content: center;
    align-items: center;

    // temp so we can see
    // width: 4px;
    // height: 4px;
    // margin: -2px 0 0 -2px;
    width: 1px;
    height: 1px;
    margin: 0;
}
.ih-modal-tap-target-helper {
    position: absolute;
    // height will be calulated and styled inline
}
.ih-modal-tap-target-helper > * {
    color: #444 !important;
}

.ih-modal-tap-target-circle-outer {
    width: 760px;
    height: 760px;
    border-radius: 50%;
    flex: 1 0 auto;

    background-color: rgba(0, 109, 216, 0.96); // ihouse blue
    // background-color: rgba(33, 150, 243, 0.96); // blue

//    background-color: rgba(0,0,0,0.9); // black

    transition: all 0.3s ease-in-out;
    transform: scale( 0.25 );
}
.ih-modal.open .ih-modal-tap-target-circle-outer {
    transform: scale( 1 );
}

.app-turboleads .ih-modal-tap-target-circle-outer {
    background-color: rgba(220, 54, 0, 0.96); // red
}

.ih-modal-tap-target-circle-inner {
    width: 87px;
    height: 87px;
    box-sizing: content-box;
    border: 1px solid rgba(255,255,255,1);
    border-radius: 50%;
    flex: 1 0 auto;

    background-clip: padding-box;

    transition: all 0.3s ease-in-out;
    background-color: rgba(255,255,255,0.5);
    transform: scale( 0.5 );
}
.ih-modal.open .ih-modal-tap-target-circle-inner {
    background-color: rgba(255,255,255,1);
    transform: scale( 1 );
}

.ih-modal-tap-target {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    flex: 1 0 auto;

    background-color: transparent;
    cursor: pointer;
}




@keyframes ih-anim-tap-target-pulsate {
    0% {
        transform: scale(1);
        border-width: 1px;
        border-color: rgba(255,255,255,1);
    }
    60% {
        transform: scale(1.1);
        border-width: 0px;
        border-color: rgba(255,255,255,1);
    }
    61% {
        border-color: rgba(255,255,255,0.5);
    }
    100% {
        transform: scale(1);
        border-width: 52px;
        border-color: rgba(255,255,255,0);
    }
}
.ih-anim-tap-target-pulsate {
    animation-name: ih-anim-tap-target-pulsate;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    transform-origin: center center;
    animation-duration: 1s;
}





.modal-select-list,
.modal-select-list > li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.ih-simple-modal-large .modal-select-list > li {
    margin: 4px 0;
}
.modal-select-list > li > a {
    display: block;
    padding: 16px 24px;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none !important;
    color: #444 !important;
}

.modal-select-list > li.selected > a {
    background-color: #efefef;
}


.modal-select-list > li .icon {
    fill: #ccc;
    float: left;
    margin-right: 8px;
    margin-top: -2px;
}
.modal-select-list > li.selected .icon {
    fill: $checked-color;
}


.modal-select-list > li > a:hover {
    background-color: #efefef;
}

.two-line-list {
    padding: 0 0 8px 0;
}
.two-line-list > li > a {
//    display: flex;
//    flex-flow: row wrap;
//    justify-content: flex-start;
//    align-items: center;
//    height: 88px;
//    padding: 0 24px;
    display: block;
    height: 76px;
    padding-top: 16px;
    padding-bottom: 16px;

    white-space: nowrap;
}



/* TODO: need to namespace these better? */
.primary-text {
    color: #444 !important;

    @include text-overflow-ellipsis;
}
.secondary-text {
    font-size: 0.875rem;
    color: #888 !important;

    @include text-overflow-ellipsis;
}




/* spinner buttons */
.ih-modal-spinner .ih-modal-button {
    background-color: transparent;
    box-shadow: none;
}
.ih-modal-spinner .ih-modal-button > span {
    background-color: transparent;
    color: #ff0000;
}
.ih-modal-spinner .ih-modal-footer {
    border-top: 1px solid #484848;
    text-align: right;
}
