// TODO: replace with toolbar, then remove this file
/*
        Material Design component
*/

.navbar {
    position: relative;
//    position: fixed; // this gets changed dynamically via js on DOM loaded
    top: 0;
    left: 0;
    right: 0;

    // negate bootstrap styles
    padding: 0;
    border-radius: 0;

    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    background: linear-gradient(to bottom, #e44c00, #d41d00);

    // z-index and box-shadow effects
    @include z-depth-1;
    z-index: 50;
}
.navbar-placeholder {
    flex: 0 1 auto;
}
.navbar > nav {
    position: relative;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    min-height: 56px;
    padding: 4px 0;
}


.navbar > nav.with-tabs {
    flex-flow: row wrap;
    padding-bottom: 0;
}

.navbar-icon {
    @include icon-wrapper;
    flex: 0 0 48px;
    color: #fff;
}
.navbar-icon:hover {
    color: #fff;
}

// negate bad bootstrap styles
a.navbar-icon:hover,
a.navbar-icon:focus {
    color: #fff;
    outline: none;
}


.navbar-icon.navbar-icon-text {
    text-transform: uppercase;
    font-size: 14px;
    flex: none;

    width: auto;
    padding: 0 16px;
    color: #fff;
    text-decoration: none !important;
}

.navbar-title {
    flex: 1 1 auto;

    display: block;
    height: 48px;
    padding: 0 12px 0 24px;

    font-size: 20px;
    line-height: 48px;
    color: #fff;
    box-sizing: border-box;
}
.navbar-title.marquee {
    position: relative;
    overflow: hidden;
    padding: 0;
}
.navbar-title.marquee > * {
    position: absolute;
    padding: 0 12px 0 24px;
}

.navbar-icon-more-tabs {
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    background-color: red;
    z-index: 1;
    cursor: pointer;
}


/* hide default navbar when either searching or selecting */
.select-mode .default-nav,



/*
 * Select mode navbar
 */
.select-mode-nav {
    display: none !important;
    background: #1d529a;
}
.select-mode .select-mode-nav {
    display: flex !important;
}
/* hide default nav and tabs while selecting */
.select-mode .default-nav,
.select-mode .navbar .tabs,
.select-mode .navbar .scrollable-tabs {
    display: none !important;
}



/*
 * Search mode navbar
 */
.search-mode-nav {
    display: none !important;
    background: #fff;
}
.search-mode .search-mode-nav {
    display: flex !important;
}
/* hide search nav while selecting within its results */
.search-mode.select-mode .search-mode-nav {
    display: none !important;
}
/* hide default nav and tabs while searching */
.search-mode .default-nav,
.search-mode .navbar .tabs,
.search-mode .navbar .scrollable-tabs {
    display: none !important;
}

.search-mode-nav .navbar-icon {
    color: #666;
}
.search-mode-nav .search-field {
    flex: 1 1 auto;

    display: block;
    height: 48px;
    padding: 0 12px 0 16px;
    margin: -4px;

    line-height: 48px;
    box-sizing: border-box;
}
.search-mode-nav input {
    width: 100%;
    height: 56px;
    padding: 20px 12px;
    border: 0;
}


/*
        React component
*/
.nav-bar {
    @include z-depth-1;

    background: linear-gradient(to bottom, #e44c00, #d41d00);
    font-family: 'Roboto', sans-serif;
    left: 0;
    right: 0;
    top: 0;
    white-space: nowrap;
    z-index: 50;

    nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        min-height: 56px;
        padding: 4px 0;
        position: relative;

        .nav-icon {
            @include icon-wrapper;

            color: #fff;
            flex: 0 0 48px;
        }

        .nav-action {
            @include icon-wrapper;

            color: #fff;
            flex: none;
            font-size: 14px;
            padding: 0 16px;
            text-transform: uppercase;
            width: auto;
        }

        .title {
            box-sizing: border-box;
            color: #fff;
            flex: 1 1 auto;
            font-size: 20px;
            height: 48px;
            line-height: 48px;
            overflow: hidden;
            padding: 0 12px 0 24px;
            text-overflow: ellipsis;
        }

        &.search-nav {
            background: #fff;
            padding: 0;

            .nav-icon {
                color: #666;
                margin-top: 4px;
                margin-right: 12px;
                cursor: pointer;
            }

            .title {
                display: none;
            }

            input {
                width: 100%;
                height: 56px;
                padding: 20px 12px;
                border: 0;
                cursor: auto;
            }
        }

        &.select-nav {
            background: #1d529a;
        }
    }
}

.nav-bar-placeholder {
    flex: 0 1 auto;
}
