.switch {

    input {
        display: none;
    }
    label {
        font-size: 0.8rem;
        color: #9e9e9e;
        line-height: 1.5;
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        cursor: pointer;
    }
    label .lever {
        content: "";
        display: inline-block;
        position: relative;
        width: 32px;
        height: 15px;
        background-color: #b0afaf;
        border-radius: 15px;
        transition: background 0.3s ease-in-out;
        vertical-align: middle;
        margin: 0 16px;
    }
    label .lever:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 21px;
        height: 21px;
        background-color: #f5f5f5;
        border-radius: 21px;
        box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4);
        left: -5px;
        top: -3px;
        transition: left 0.3s ease-in-out, background .3s ease-in-out, box-shadow 0.1s ease-in-out;
    }

    /* hover */
    label:hover .lever {
        background-color: #939393;
    }
    label:hover .lever:after {
        background-color: #ffffff;
    }

    /* checked */
    label input[type=checkbox]:checked+.lever {
        background-color: desaturate( lighten($checked-color, 30%), 30%);
    //    background-color: fade(lighten($checked-color, 16%), 28%);
    //    background-color: $checked-color;
    }
    label input[type=checkbox]:checked+.lever:after {
        background-color: $checked-color;
    //    background-color: #fff;
        left: 16px;
    }
}


/*
 * Switch bar
 */
.switch-bar {
    position: relative;
    background-color: #666;
    margin-bottom: 0.5rem;
    overflow: hidden;
}
.switch-bar > label {
    position: relative;
    display: block;
    width: 100%;
    height: 56px;
    padding: 16px 0;
    margin: 0;
    box-sizing: border-box;
    color: #fff;
    text-align: right;
    z-index: 1;
}

.switch-bar-label {
    position: absolute;
    top: 0;
    left: 72px;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
}
.switch-bar-label:before {
    content: 'On';
    display: none;
}
.switch label input[type=checkbox]:checked ~ .switch-bar-label:before {
    display: block;
}
.switch-bar-label:after {
    content: 'Off';
    display: block;
}
.switch label input[type=checkbox]:checked ~ .switch-bar-label:after {
    display: none;
}
.switch-bar label .lever {
    margin-top: 1px;
    z-index: 1;
}
