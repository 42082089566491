@mixin material-design-input {
    input {
        transition: none;
        max-width: none;
        background-clip: padding-box;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        border-radius: 0;
        box-sizing: border-box;
        color: #222;
        display: block;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        margin: 0;
        padding: 0;
        box-shadow: none;

        &:focus {
            border: none;
            border-bottom: 2px solid #008eff !important;
            box-shadow: none;
        }
    }
}

.dialog {
    bottom: 0;
    color: #666;
    cursor: default;
    font-family: Helevetica, Arial, sans-serif;
    font-size: 16px;
    height: 100%;
    left: 0;
    line-height: 1.25;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: left;
    top: 0;
    transition: 300ms;
    white-space: normal;
    width: 100%;
    z-index: 9000;

    .dialog-container {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 10px;
        position: relative;
        transition: 300ms ease-in-out;
        width: 100%;
        z-index: 9020;

        @media all and (min-width: $bp-tablet-portrait) {
            left: 50%;
            margin-left: -300px;
            padding-bottom: 30px;
            right: auto;
            width: 600px;
        }

        @media all and (min-width: $bp-tablet-portrait) and (min-height: $bp-sm-tablet-portrait) {
            padding-top: 50px;
        }

        @media all and (min-width: $bp-tablet-portrait) and (min-height: $bp-tablet-portrait) {
            padding-top: 100px;
        }

        .dialog-content {
            background-clip: padding-box;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            outline: 0;
            padding-top: 20px;
            position: relative;

            .dialog-header {
                h4 {
                    color: #222;
                    font-family: 'Roboto', Arial, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    margin: 0;
                    padding: 0 36px 8px 24px;
                }

                button {
                    background-color: transparent;
                    border: 0;
                    color: #bbb !important;
                    font-size: 20px;
                    font-weight: bold;
                    height: 24px;
                    line-height: 24px;
                    margin: 0;
                    min-width: unset;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    text-shadow: none;
                    top: 10px;
                    width: 24px;

                    &:hover {
                        color: #666 !important;
                    }
                }
            }

            .dialog-body {
                padding: 4px 36px 24px 24px;

                .share-link-content {
                    position: relative;

                    span {
                        position: absolute;
                        top: 2px;
                        right: 26px;
                        cursor: pointer;
                    }

                    @media only screen and (max-width: $bp-gt-phone-landscape) {
                        span {
                            right: 0;
                        }
                    }

                    input {
                        width: 85%;
                        border: none;
                        margin-right: 10px;
                        background: none;
                        box-shadow: none;
                        border-bottom: 1px solid #aaa;
                        border-radius: 0;

                        &:focus {
                            border: none;
                            border-bottom: 2px solid #0066cc;
                            box-shadow: none;
                        }
                    }

                    a {
                       font-size: 15px;
                        display: block;
                        margin-bottom: 8px;
                    }
                }

                a {
                    color: #0066cc;
                }

            }

            .dialog-footer {
                border-top: 1px solid #efefef;
                padding: 10px;
                text-align: right;

                .btn {
                    margin-left: 6px;
                }
            }
        }
    }

    &.open-enter {
        opacity: 0.01;

        &.open-enter-active {
            opacity: 1;
        }
    }

    &.open-exit {
        opacity: 1;

        &.open-exit-active {
            opacity: 0.01;
        }
    }

    /*=================*/
    /*= DIALOG THEMES =*/
    /*=================*/

    @mixin dialog-theme-default {
        &.open-enter {
            .dialog-container {
                transform: translate(0, -25%);
            }

            &.open-enter-active {
                .dialog-container {
                    transform: translate(0, 0);
                }
            }
        }

        &.open-exit {
            .dialog-container {
                transform: translate(0, 0);
            }

            &.open-exit-active {
                .dialog-container {
                    transform: translate(0, -25%);
                }
            }
        }
    }

    &.theme-default {
        @include dialog-theme-default;
    }

    @mixin dialog-theme-select {
        @include dialog-theme-default;

        .dialog-container {
            width: 300px;

            @media all and (min-width: $bp-tablet-portrait) {
                margin-left: -150px;
            }

            .dialog-body {
                padding: 4px 0 12px 0;

                li {
                    padding-left: 24px;
                    padding-right: 24px;

                    &:hover,
                    &.checked {
                        background-color: #efefef;
                    }

                    &.divider {
                        border: 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        display: block;
                        height: 0;
                        margin: 0;
                        overflow: hidden;
                        padding: 0;
                    }
                }
            }
        }
    }

    &.theme-multi-auto-complete {
        .dialog-body {
            .Select--multi .Select-clear-zone {
                padding-top: 4px;
            }
        }
    }

    &.theme-select {
        @include dialog-theme-select;
    }

    @mixin dialog-theme-bottom-sheet {
        z-index: 999999;

        .dialog-container {
            bottom: 0;
            padding: 0;
            position: absolute;
            top: auto;

            .dialog-content {
                border-radius: 0;
                padding-bottom: 12px;

                .dialog-body {
                    font-size: 14px;
                }
            }
        }

        &.open-enter {
            .dialog-container {
                transform: translate(0, 25%);
            }

            &.open-enter-active {
                .dialog-container {
                    transform: translate(0, 0);
                }
            }
        }



        &.open-exit {
            .dialog-container {
                transform: translate(0, 0);
            }

            &.open-exit-active {
                .dialog-container {
                    transform: translate(0, 25%);
                }
            }
        }
    }

    &.theme-bottom-sheet {
        @include dialog-theme-bottom-sheet;

        &.red-bottom-sheet {
            .dialog-content {
                background-color: rgba(220, 54, 0, 0.96);

                .dialog-header {
                    h4, button {
                        color: white !important;
                    }
                }

                .dialog-body {
                    color: white;
                }
            }
        }
    }

    @mixin dialog-theme-snackbar {
        @include dialog-theme-bottom-sheet;

        .dialog-container {
            background-color: rgba(30, 30, 30, 0.85);
            box-shadow: none;
            color: white;

            .dialog-content {
                align-items: center;
                background-color: transparent;
                box-shadow: none;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                padding: 16px 24px;

                .dialog-body {
                    align-items: center;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    min-height: 36px;
                    padding: 0;
                }

                .dialog-footer {
                    border: 0;
                    padding: 0 0 0 16px;

                    .btn {
                        height: 36px;
                        line-height: 36px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    &.theme-snackbar {
        @include dialog-theme-snackbar;
    }

    &.theme-spinner {
        @include dialog-theme-snackbar;

        .spinner {
            padding: 0 16px 0 0;

            span {
                border: 0.2em solid rgba(255, 255, 255, 0.2);
                border-left-color: rgba(255, 255, 255, 0.5);
                border-top-color: #fff;
                width: 36px;
                height: 36px;
                border-radius: 36px;
                box-sizing: border-box;
            }
        }
    }

    &.theme-circle {
        color: rgba(255, 255, 255, 0.66);

        .overlay {
            opacity: 0.01;
        }

        .dialog-container {
            margin: 0;
            max-width: 380px;
            padding: 0;
            transition: unset;
            width: 100%;

            .dialog-content {
                background-color: transparent;
                box-shadow: none;

                .dialog-header {
                    padding-bottom: 20px;

                    h4 {
                        color: #fff;
                        padding: 0;
                    }
                }

                .dialog-body {
                    padding: 0;
                }
            }

            .target-circle {
                align-items: center;
                background-color: rgba(220, 54, 0, 0.96);
                border-radius: 50%;
                display: flex;
                height: 760px;
                justify-content: center;
                left: 0;
                margin: -380px 0 0 -380px;
                position: absolute;
                top: 0;
                transition: 300ms ease-out;
                width: 760px;

                div {
                    background-color: rgba(255, 255, 255, 1);
                    background-clip: padding-box;
                    border: 1px solid rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    box-sizing: content-box;
                    height: 86px;
                    width: 86px;
                }
            }

            .target-icon {
                align-items: center;
                cursor: pointer;
                display: flex;
                height: 64px;
                justify-content: center;
                left: 0;
                margin: -32px 0 0 -32px;
                width: 64px;
                position: absolute;
                top: 0;
                z-index: 1;

                .icon {
                    color: #444;
                    height: 24px;
                    width: 24px;
                }

                span {
                    color: #444;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
        }

        &.position-left {
            .dialog-content {
                max-width: 380px;
                padding: 60px 60px 0 20px;
            }
        }

        &.position-right {
            .dialog-content {
                margin-left: -85%;
                max-width: 330px;
                padding: 60px 20px 0 0;
            }
        }

        &.open-enter {
            .target-circle {
                transform: scale(0.25) !important;
            }

            &.open-enter-active {
                .target-circle {
                    transform: scale(1) !important;
                }
            }
        }

        &.open-exit {
            .target-circle {
                transform: scale(1) !important;
            }

            &.open-exit-active {
                .target-circle {
                    transform: scale(0.25) !important;
                }
            }
        }
    }

    &.theme-multi-select {
        @include dialog-theme-select;

        .dialog-container {
            .multi-select-field-component {
                .dialog-body {
                    padding: 4px 12px 12px 12px;
                }
            }
        }
    }

    &.theme-auto-complete {
        line-height: normal;
    }

    &.theme-tags {
        .dialog-body{
            @include material-design-input;
        }

        line-height: normal;

        .selected-tags {
            display: flex;
            flex-wrap: wrap;
            max-height: 250px;
            overflow-y: auto;
            margin-bottom: 15px;
        }
    }

    &.theme-list-options {
        @include dialog-theme-default;

        .dialog-body {
            padding: 4px 0 24px 0 !important;

            li {
                border-bottom: none;
                margin: 4px 0;
                white-space: nowrap;

                &:hover,
                &.selected {
                    background-color: #efefef;
                }

                .list-item-main-component {
                    height: 76px;
                    padding-bottom: 16px;
                    padding-top: 16px;
                }

                .title-block,
                .content-block {
                    @include text-overflow-ellipsis;

                    padding: 0 24px;
                }

                .title-block {
                    color: #444;
                    font-size: 16px;
                }

                .content-block {
                    color: #888;
                    font-size: 14px;
                }
            }
        }
    }

    &.theme-saved-searches {
        .dialog-container {
            height: 100%;

            .dialog-content {
                display: table;
                empty-cells: show;
                border-collapse: collapse;
                width: 100%;
                height: 100%;

                .dialog-header {
                    h4 {
                        padding: 16px 36px 12px 28px;
                    }
                }

                .dialog-body {
                    padding: 0;
                    display: table-row;
                    height: 100%;
                    overflow: hidden;

                    .saved-search-iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                        margin: 0;
                        padding: 0;
                        display: block;
                    }
                }
            }
        }
    }
}
