/* extra and override bootstrap variables */

$gray-darker:               #222 !default;
$brand-info-alt:            $purple !default;


// make all badges circles/pills
.badge {
    height: 16px;
    min-width: 16px;
    padding: 0 5px;
    border-radius: 8px;

    font-size: 10.5px;
    font-weight: 500;
    line-height: 16px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}
