.control-bar-component {
    @include z-depth-1-half;

    align-items: center;
    background-color: #fff;
    color: #aaa;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 8px 16px 12px 16px;

    .switch-field-component {
        .form-field-input {
            border: none;
        }

        .form-error {
            display: none;
        }
    }

    &.settings-control-bar {
        background-color: #666;
        box-shadow: none;
        justify-content: space-between;
        padding-bottom: 6px;
        padding-top: 2px;
    }
}