

/*
 * Edit Contact View
 */

.edit-contact-page {
    /* add space at the bottom */
    margin-bottom: 2*$units;


    .fieldset .fieldset-action .expand-fieldset {
        display: flex;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    /* name fieldset */
    .fieldset .name-advanced-field {
        display: none;
    }
    /* when expanded, the arrow icon flips the other direction */
    .fieldset.expanded .fieldset-action .expand-fieldset {
        transform: rotate(180deg);
    }
    /* advanced fields prefix, middle name, and suffix show up if
     * 1. they have a value
     * 2. or if the fieldset is expanded
     */
    .fieldset .name-advanced-field.has-value,
    .fieldset.expanded .name-advanced-field {
        display: block;
    }
    /* if all name fields have values, hide the toggle arrow icon */
    .fieldset.has-all-values .expand-fieldset {
        display: none;
    }

    .fieldset-action .remove-fieldset {
        display: none;
    }
    .fieldset.has-value .fieldset-action .remove-fieldset {
        display: flex;
        cursor: pointer;
    }


    /* select box text appears gray until the main text field
     * that they are grouped with has a value
     */
    .fieldset .inline-select-field span {
        color: #d3d3d3;
    }
    .fieldset.has-value .inline-select-field span {
        color: #222;
    }
}
