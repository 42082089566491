
.listing-flyer-container {
    display: flex;

    .listing-flyer-right {
        width: 60%;
    }

    .listing-flyer-left {
        width: 40%;
        margin-right: 2em;
    }
    label {
        padding-left:.5em;
    }
    .form-field-input,
    .radio-field-component {
        padding:.5em;
    }
    
    .radio-field-component ul li {
        padding:0;
    }

}


@media only screen and (max-width: $mobile-breakpoint) {
    .listing-flyer-container {
        flex-direction: column;

        .listing-flyer-left,
        .listing-flyer-right {
            width: 100%;
        }

        .listing-flyer-right {
            margin-right: 0 !important;
        }
    }
}

.listing-flyer-iframe {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    border: none;
    margin: 1em 0 2em 0;
    padding: 0;
    display: block;
}

.listing-flyer-error {
    color:red;
    font-style:italic;
}

.Select-multi-value-wrapper {
    max-height: 200px;
    overflow: auto;
    min-height: 50px;
    height: 100%;
    width: 100%;
}

