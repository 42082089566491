

// TODO: file needs cleanup


/*
 * ========================================
 * Contacts/Pipeline View
 * ========================================
 */

/* add space at the bottom to allow last list item to be fully visible above the "add contact" button */
.pipeline-view .lead-list,
.contacts-view .lead-list {
    margin-bottom: 2*$units;
}

.lead-list-item {
    background-color: #efefef;
    box-shadow: inset 1px 2px 5px rgba(0, 0, 0, 0.2);
}


.lead-status,
.lead-source,
.lead-age {
    @include small;
}
.lead-status {
    color: inherit;
}

//.task-description,
.task-date {
    @include small;
}


/*
 * application specific implementations...
 */
.lead-tile {
    cursor: pointer;
}

.list-item.open > .lead-tile {
    left: -180px;
}

.list-item > .lead-tile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    background-color: #fff;
    transition: 0.3s all ease-in-out;
    left: 0px;
}
.lead-tile .tile-extras .lead-more {
    margin-bottom: 2px;
}

.tile-single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aaa;
}
.tile-single-line .lead-status {
    color: #373a3c;
}


.loading-more-results {
    display: none;
    padding: 16px;
    text-align: center;
}
.loading-results .loading-more-results {
    display: block;
}



/* overdue leads */
//.lead-list-item.overdue .lead-status:before {
//    content: '!';
//    position: relative;
//    top: -1px;
//    display: inline-block;
//    color: #fff;
//    background-color: #d70206;
//    border-radius: 50%;
//    width: 8px;
//    height: 8px;
//    padding: 2px;
//    box-sizing: content-box;
//    line-height: 8px;
//    text-align: center;
//    font-size: 12px;
//    font-weight: bold;
//    margin-right: 6px;
//    font-family: 'Times New Roman';
//}
.lead-list-item.overdue .lead-age {
    color: #d70206;
}




/*
 * ========================================
 * Contact Details View
 * ========================================
 */


/* for smaller mobile screens, collapse the edit button into the more menu */
.lead-details-nav .navbar-icon-edit {
    display: none;

    @media all and (min-width: 375px) {
        display: flex;
    }
}
.lead-details-nav .more-menu .edit {
    @media all and (min-width: 375px) {
        display: none;
    }
}





.contact-info-list-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    height: 72px;
    padding: 0;

    background-color: #fff;
    opacity: 1;


    & > * {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .list-item-pre {
        width: 64px;
        padding-left: 2px;
    }

    .list-item-main {
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);
        font-size: $list-item-font-size;
    }
    &:last-child .list-item-main {
        border-bottom: 0;
    }
}






.contact-about-list-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: auto;
    padding: 0 0 0 24px;

    background-color: #fff;
    opacity: 1;

    & > * {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .list-item-main {
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);
        font-size: $list-item-font-size;
        color: inherit !important;
        text-decoration: none !important;
    }
    &:last-child .list-item-main {
        border-bottom: 0;
    }
}
