.drawer {
    bottom: 0;
    font-size: 14px;
    position: fixed;
    top: 0;
    transition: 300ms;
    z-index: 9010;

    &.side-left {
        left: -280px;
        right: auto;
    }

    &.side-right {
        left: auto;
        right: -280px;

        .drawer-content {
            .drawer-close {
                right: 4px;
                left: auto;
            }
        }
    }

    &.panning {
        transition: unset;

        .overlay {
            transition: unset;
        }
    }

    &.panning,
    &.active {
        .drawer-content {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        }
    }

    &.active {
        &.side-left {
            left: 0;
        }

        &.side-right {
            right: 0;
        }
    }

    &.filter-drawer {
        header {
            position: relative;
            box-sizing: border-box;
            height: 56px;
            padding: 4px 0 4px 24px;
            color: #fff;
            background: linear-gradient(to bottom, #e44c00, #d41d00);
            margin-bottom: 1em;

            span {
                display: block;
                height: 48px;
                padding: 0;
                font-size: 20px;
                line-height: 48px;
                color: #fff;
                box-sizing: border-box;
            }
        }

        .drawer-content {
            nav {
                .filter-body {
                    margin: 10px 12px;
                }

                label {
                    color: #48aeff;
                }

                .sort-divider {
                    margin-bottom: 2.25em;
                }
            }
        }
    }

    .drawer-content {
        background-color: #fff;
        height: 100%;
        overflow-y: auto;
        position: relative;
        touch-action: pan-y !important;
        width: 280px;
        z-index: 9030;

        .drawer-close {
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            height: 48px;
            left: 4px;
            line-height: 60px;
            position: absolute;
            right: auto;
            text-align: center;
            top: 4px;
            width: 48px;
            z-index: 9030;
        }
    }
}
