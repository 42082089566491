.spinner {
    display: block;
    padding: 16px;
    text-align: center;

    span {
        box-sizing: content-box;
        border: 0.2em solid rgba(0, 0, 0, 0.2);
        border-left-color: rgba(0, 0, 0, 0.5);
        border-radius: 1em;
        border-top-color: #000;
        display: inline-block;
        height: 1em;
        vertical-align: middle;
        width: 1em;
    }
}
