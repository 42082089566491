
.user-profile {

}
.user-profile-header {
    position: relative;
    width: 100%;
    height: 200px;
    padding: 0;

    background: #444 no-repeat center center;
    background-size: contain;
}
.user-profile-header .user-profile-name {
    position: absolute;
    bottom: 8px;
    left: 12px;
    color: #fff;
    font-size: 20px;
}
.user-profile-photo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: auto;
    height: auto;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.user-profile-header .ic-account-circle {
    fill: #fff;
    opacity: 0.1;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 96px;
    width: 96px;
    margin: -48px 0 0 -48px;
}



.user-profile-field-list-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    height: auto;
    padding: 0 12px;

    background-color: #fff;
    opacity: 1;

    & > * {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .list-item-main {
        height: 72px;
        padding-top: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);

        .list-item-action.button {
            width: auto;
            font-size: 14px;
            padding: 0;
        }
    }
    &:last-child .list-item-main {
        border-bottom: 0;
    }
}



/*
 * profile edit
 */
.photo-field {

}
.photo-field .user-profile-photo {
    height: 200px;
    padding: 0;
}

.photo-field-icon {
    @include icon-wrapper;
    color: #fff;

    position: absolute;
    bottom: 0;
    right: 0;
}
.profile-edit-view .fieldset-fields {
    padding-right: 16px;
}
.profile-edit-view .fieldset-action {
    display: none;
}


/*
 * Change Password
 */

.password-fieldset {
    padding-right: 12px;
}
.password-fieldset .fieldset-pre {
    padding-top: 26px;
}



/*
 * Verify Phone/Email Pages
 */
.verify-page {
    padding: 16px;
}
.verify-page p {
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.verify-form .field {
//    padding-right: 20px;
}
