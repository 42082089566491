$chip-height: 32px;
$chip-color: #e0e0e0;

.chip {
    font-family: "Roboto", sans-serif;
    display: inline-block;
    background: $chip-color;
    padding: 0 12px;
    border-radius: 32px;
    font-size: 13px;
    height: $chip-height;
    line-height: $chip-height;
    margin: 3px;

    &.removable {
        &:hover {
            background: #ccc;
        }
    }
}

.chip-remove {
    display: inline-block;
    background: #aaa;
    border: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    padding: 0;
    margin: 0 -4px 1px 4px;
    cursor: pointer;
    font: inherit;
    line-height: 20px;
    text-transform: lowercase;
    text-shadow: none;

    &:after {
        color: $chip-color;
        content: 'x';
    }

    &:hover {
        background: #999;
    }

    &:active {
        background: #777;
    }
}