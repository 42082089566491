/*
        OLD STUFF
*/

.list,
.list-item {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}
.list-item {
    opacity: 1;
    height: 72px;
    transition: 0.3s all ease-in-out;

    &.three-line-list-item {
        height: 88px;
        line-height: 1.25;
    }
}

.list-item-pre {
    flex: none;
    width: 56px;
}
.list-item-main {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
}

.list-item-post,
.list-item-action {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;
    margin: 0 4px;

    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
}
.list-item-action,
.list-item-action:hover,
.list-item-action:active,
.list-item-action:visited {
    color: #f34e00;
}

// disabled state
.list-item-action.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: #999 !important;
}


.list-item-main.with-action {
//    padding-right: 60px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.list-item-main .list-item-action {
    flex: none;
    width: 56px;
    margin: 0;
}
.list-item-main-content {
    flex: 1 1 auto;
}
.list-item-main-buttons {
    padding: 8px 0 0 0;
    margin: 12px 0;
    border-top: 1px solid rgba(0,0,0,0.065);
}




.list-empty {
    text-align: center;

    display: flex;
    height: 72px;
    flex-flow: column nowrap;
    justify-content: center;
}

.list > li.divider {
    margin: 8px 0;
}



.radio-list-item select {
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    border: 0;
    opacity: 0;
    z-index: 1;

    cursor: pointer;
}


//task list

.task-toggle-container {
    display: flex;
    justify-content: center;
    color: #f34e00;
}

/*
        NEW STUFF
*/

.select-boxes-list {
    > li {
        border-bottom: none;

        .content-block {
            display: flex;
            align-items: flex-end;
            width: 100%;

            .select-field-component {
                width: 200px;
                margin-right: 24px;

                span {
                    font-size: 15px;
                }
            }
        }
    }
}

.list-component {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);
        box-sizing: border-box;
        position: relative;

        &:last-child {
            border-bottom: none;
        }

        .list-item-body-component {
            align-items: center;
            background-color: #fff;
            display: flex;
            flex-flow: row nowrap;
            height: 100%;
            justify-content: flex-start;
            left: 0;
            padding: 8px 16px;
            position: relative;
            transition: 300ms ease-in-out;

            .list-item-prefix-component {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 40px;
                padding-right: 16px;
                position: relative;
            }

            .list-item-main-component {
                flex: 1 1 auto;
                min-width: 0;
                position: relative;

                .title-block {
                    font-size: 15px;
                    padding-bottom: 4px;
                }

                .sub-title-block {
                    font-size: 13px;
                    padding-bottom: 4px;
                }

                .content-block {
                    color: #aaa;
                    font-size: 13px;

                    b {
                        font-family: "Roboto", sans-serif;
                    }
                }
            }

            .list-item-suffix-component {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 40px;
                padding-left: 16px;
                position: relative;
                text-align: right;
                white-space: nowrap;
            }
        }

        .list-item-footer-component {
            box-sizing: border-box;
            margin-left: 72px;
            padding-bottom: 4px;
            position: relative;

            .btn {
                padding: 0;
            }
        }

        .list-item-tray-component {
            align-items: center;
            background-color: #efefef;
            bottom: 0;
            box-shadow: inset 1px 2px 5px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            .list-item-action-component {
                margin: 0 4px;
            }

            .assignment-container {
                width: 180px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .list-item-action-component {
            align-items: center;
            color: $red-orange !important;
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            font-size: 1rem;
            justify-content: center;
            min-height: 48px;
            min-width: 48px;
            text-decoration: none !important;

            &.disabled {
                color: #999 !important;
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &.open-tray {
            .list-item-body-component {
                left: -180px;
            }
        }


        .menu {
            z-index: 30;
        }
    }

    @mixin basic-list {
        margin-left: 20px;

        .list-item-body-component {
            padding-left: 0;
            padding-right: 8px;
        }
    }

    &.empty-list {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 24px 16px;

        &.center {
            text-align: center;
        }

        &.left {
            text-align: left;
        }
    }

    @mixin simple-list {
        > li {
            .list-item-body-component {
                padding: 12px 0;

                .title-block {
                    font-size: 16px !important;
                }

                .btn {
                    padding: 0 10px;
                }
            }
        }
    }

    &.auto-complete-list {
        position: absolute;
        width: 100%;
        border: 1px solid #eee;
        max-height: 350px;
        overflow-y: auto;
        height: auto;
        top: 48px;

        > li {
            cursor: pointer;
            border-bottom: 1px solid #eee;

            &:only-child, &:last-child {
                border-bottom: none;
            }

            .list-item-body-component {
                transition: none !important;

                &:hover {
                    background-color: #efefef;
                }
            }
        }
    }

    &.selected-tags-list {
        > li {
            border: none;
            width: auto;
        }
    }

    &.desktop-contact-list {
        > li {
            height: 72px;

            .list-item-prefix-component, .list-item-suffix-component {
                cursor: pointer;
            }

            &.list-data {
                &.selected {
                    .list-item-body-component {
                        background-color: #efefef;
                    }
                }
            }

            &.list-header {
                height: 52px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.065);

                .list-item-body-component {
                    padding: 12px 16px;
                    align-items: flex-end;

                    .list-item-prefix-component {
                        justify-content: flex-end;
                    }
                }

                .prefix-spacer {
                    width: 60px;
                }

                .suffix-spacer {
                    width: 40px;
                }
            }

            .list-item-body-component {
                .list-item-prefix-component {
                    cursor: pointer;
                    padding-right: 20px;

                    span {
                       display: inline-block;
                       margin-right: 16px;
                    }
                }

                .list-item-main-component {
                    .title-block {
                        display: none;
                    }

                    .sm-col, .md-col, .lg-col {
                        margin-left: 15px;
                    }

                    .sm-col {
                        width: 10%;
                    }

                    .md-col {
                        width: 15%;
                    }

                    .lg-col {
                        width: 25%;
                    }

                    .content-block {
                       display: flex;

                        .list-col {
                           color: rgb(41, 43, 44);
                           font-size: 15px;
                           min-width: 0;
                           overflow: hidden;
                           text-overflow: ellipsis;
                           white-space: nowrap;

                            &.name-col, &.email-col, &.phone-col {
                                cursor: pointer;
                            }

                            &.overdue {
                                color: rgb(215, 2, 6);
                            }
                        }
                    }
                }
            }
        }
    }

    &.basic-list {
        @include basic-list;
    }

    &.simple-list {
        @include simple-list;
    }

    &.getting-started-list {
        > li {
            .description-block,
            .list-item-footer-component {
                display: none;
            }

            &.active {
                .circle-front {
                    background-color: rgb(156, 204, 160);
                }

                .description-block,
                .list-item-footer-component {
                    display: block;
                }
            }

            &.completed {
                border-bottom-color: #fff;

                .list-item-body-component {
                    background-color: #efefef;

                    .title-block {
                        color: #aaa;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    &.contact-list {
        > li {
            height: 72px;

            &.three-line-item {
                height: 88px !important;

                .title-block,
                .sub-title-block,
                .content-block {
                    padding-bottom: 0;
                }
            }

            &.selected {
                .list-item-body-component {
                    background-color: #efefef;
                }
            }

            .list-item-body-component {
                .title-block,
                .content-block {
                    min-width: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .list-item-main-component {
                    cursor: pointer;

                    .title-block {
                        font-size: 16px !important;
                    }

                    .contact-status {
                        color: #373a3c;
                    }
                }

                .contact-more {
                    cursor: pointer;
                }

                .contact-age {
                    color: #aaa;
                    font-size: 13px;
                }
            }

            &.overdue {
                .contact-age {
                    color: #d70206;
                }
            }
        }
    }

    &.link-list {
        > li {
            height: 64px;
        }
    }

    &.preview-list {
        margin-left: 20px;

        > li {
            .list-item-body-component {
                padding: 16px 8px 0 0;

                .list-item-main-component {
                    .content-block {
                        .form-field-component {
                            &.textarea-field-component {
                                .form-field-input {
                                    padding: 14px 0 12px 0;
                                }
                            }

                            .form-field-input {
                                border-bottom: none;
                            }

                            .form-error {
                                min-height: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &.task-list {
        > li {
            height:auto;

            .list-item-body-component {
                padding-right: 8px;
                padding-top: 13px;
                padding-bottom: 14px;

                .list-item-prefix-component {
                    cursor: pointer;

                    .icon-checkbox {
                        color: $red-orange;
                        display: block;
                    }

                    .icon-checkbox-checked {
                        color: $light-gray;
                        display: none;
                    }
                }

                .list-item-main-component {
                    cursor: pointer;

                    .title-block,
                    .content-block {
                        min-width: 0;
                        white-space: normal;
                    }
                }

                .list-item-suffix-component {
                    padding: 0;

                    .dropdown-icon {
                        height: 40px;
                        padding: 0 8px 0 4px;
                    }
                }
            }

            &.completed {
                .icon-checkbox {
                         display: none !important;
                     }

                .icon-checkbox-checked {
                    display: block !important;
                }

                .title-block {
                    color: $gray;
                    text-decoration: line-through;
                }
            }

            &.overdue {
                .content-block::before {
                    background-color: $dark-red;
                    border-radius: 50%;
                    box-sizing: content-box;
                    color: #fff;
                    content: "!";
                    display: inline-block;
                    font-family: "Times New Roman", serif;
                    font-size: 12px;
                    font-weight: bold;
                    height: 12px;
                    line-height: 12px;
                    margin-right: 4px;
                    position: relative;
                    text-align: center;
                    top: -1px;
                    width: 12px;
                }
            }
        }
    }

    &.campaign-list {
        > li {
            .list-item-body-component {
                padding: 8px 20px;
            }
        }
    }

    &.saved-listings-list {
        > li {
            .list-item-body-component {
                padding: 12px 16px;
                .list-item-prefix-component {
                    img {
                        width: 130px;
                    }
                }

                .list-item-main-component {
                    .title-block {
                        @include text-overflow-ellipsis;
                    }
                }
                
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }

    &.saved-searches-list {
        > li {
            .list-item-body-component {
                padding: 12px 16px;
                .list-item-prefix-component {
                    .listing-thumbnail {
                        width: 120px;
                        height: 90px;
                        background-size: cover;
                        background-position: center bottom;
                    }
                }

                .list-item-main-component {
                    .title-block {
                        @include text-overflow-wrap;
                    }
                }
                
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }

    &.saved-searches-list {
        margin-left: 20px;

        > li {
            width: 100%;

            .list-item-body-component {
                padding: 12px 16px 12px 0;

                .list-item-main-component {
                    .content-block {
                        @include text-overflow-wrap;
                    }
                }
            }

            &.ht-settings-item {
                display: inline-block;
                width: 100%;

                .list-item-body-component {
                    min-height: 72px;
                    padding: 8px 16px 8px 0;
                }

                @mixin ht-mobile-style {
                    flex-direction: column;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                }

                @media only screen and (max-width: $bp-gt-lg-desktop) and (min-width: $desktop-breakpoint) {
                    .list-item-body-component {
                        @include ht-mobile-style;
                    }
                }

                @media only screen and (max-width: 662px) {
                    .list-item-body-component {
                        @include ht-mobile-style;
                    }
                }

                .list-item-prefix-component {
                    cursor: pointer;

                    span {
                        display: flex;

                        span {
                            margin-left: 8px;
                        }
                    }
                }

                .list-item-main-component {
                    .title-block {
                        padding: 0;
                    }

                    .content-block {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        color: #222;
                        min-height: 48px;
                        min-width: 165px;

                        .radio-field-component {
                            ul {
                                display: flex;
                                align-items: center;

                                li {
                                    padding: 0 8px 0 0;
                                }
                            }
                        }

                        .select-field-component {
                            margin-left: 14px;

                            .form-field-input {
                                font-size: 15px;
                            }

                            .form-error {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.help-list {
        @include simple-list;

        > li {
            cursor: pointer;
        }

        .icon {
            color: #e44c00
        }
    }

    &.details-info-list {
        > li {
            height: 72px;

            .list-item-body-component {
                padding: 8px;

                .list-item-prefix-component {
                    padding-right: 8px;
                }

                .list-item-main-component {
                    .content-block {
                        .contact-email-badges {
                            .badge-bad-email {
                                background-color: #D63737;
                            }

                            > span {
                                margin: 0 4px;
                                display: inline-block;
                            }

                            .badge {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .list-item-suffix-component {
                    padding-left: 8px;
                }

                .title-block {
                    @include text-overflow-ellipsis;
                }
            }
        }
    }

    &.listing-flyer-list {
        > li {
            border-bottom: none;

            .list-item-body-component {
                .Select-control {
                    margin-top: 16px;
                }

                .radio-field-component {
                    li {
                        padding: 12px 0;
                        font-size: 15px;
                    }
                }

                .form-field-component {
                    width: 100%;
                }
            }
        }
    }

    &.mailchimp-account-list {
        @include basic-list;

        > li {
            &.linked-account-item {
                .switch-field-component {
                    .form-field-input {
                        border-bottom: none;
                    }
                }
            }

            @media only screen and (max-width: $bp-sm-tablet-landscape) {
                &.sync-list-item {
                    flex-direction: column;
                    align-items: flex-start !important;

                    .list-item-body-component {
                        .list-item-main-component {
                            margin-right: 0;
                        }
                    }
                }
            }

            &.sync-list-item {
                display: flex;
                align-items: center;

                &.no-synced-list {
                    flex-direction: column;
                    align-items: flex-start !important;
                }

                .list-item-body-component {
                    .list-item-main-component {
                        margin-right: 36px;

                        .select-field-component {
                            max-width: 450px;
                            margin-right: 24px;

                            .form-field-input {
                                font-size: 15px;
                            }

                            .form-error {
                                line-height: 18px;
                            }
                        }
                    }
                }

                .list-item-footer-component {
                    margin-left: 0;
                }
            }

            &.advanced-sync-item {
                @media only screen and (max-width: $bp-sm-tablet-portrait) {
                    .syncing-tables {
                        flex-direction: column;

                        .multi-select-table-field-component {
                            margin: 4px 0 0 0;
                        }
                    }
                }

                .synced-item-count {
                    margin-top: 12px;
                    font-size: 14px;
                    color: #222;
                }

                .syncing-tables {
                    display: flex;

                    .multi-select-table-field-component {
                        margin: 18px 36px 0 0;
                    }
                }
            }
        }
    }

    &.mailchimp-list-details-list {
        > li {
            border-bottom: none;

            .list-item-body-component {
                padding: 0;

                .title-block {
                    display: none;
                }

                .content-block {
                    padding-bottom: 4px;
                }
            }
        }
    }

    &.timeline-list {
        padding: 8px 0;

        > li {
            border: none;
            padding-bottom: 16px;

            &:before {
                background-color: #efefef;
                bottom: 0;
                content: '';
                display: block;
                height: auto;
                left: 36px;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:first-child {
                &:before {
                    top: 36px;
                }
            }

            &:last-child {
                padding-bottom: 8px;

                &:before {
                    bottom: auto;
                    height: 36px;
                }

                &:only-child {
                    &:before {
                        height: 0;
                    }
                }
            }

            > .list-item-body-component {
                align-items: flex-start;
                background-color: transparent;
                padding-bottom: 0;
                padding-right: 8px;

                .title-block {
                    font-size: 16px;
                    padding-bottom: 0;
                }

                .content-block {
                    .icon {
                        color: #53a95b;
                        font-size: 16px;
                        height: 16px;
                        margin: 1px 2px 0 0;
                        vertical-align: top;
                        width: 16px;

                        &.error{
                            color: #d70206;
                        }
                    }
                }

                .dropdown-icon {
                    width: 40px;
                    height: 40px;
                }

                .list-item-action-component {
                    min-width: 40px;
                    min-height: 40px;
                }
            }

            .list-item-footer-component {
                border-top: 1px solid rgba(0, 0, 0, 0.065);
                color: #aaa;
                font-size: 13px;
                margin-right: 16px;
                margin-top: 8px;
                padding: 8px 0 0 0;

                h5 {
                    color: #373a3c;
                    font-size: 13px;
                    margin: 0;
                    padding: 0 0 2px 0;
                }

                .email-thread-toggle {
                    display: block;
                    padding-top: 12px;
                }

                .spinner {
                    padding: 24px 0 0 0;
                    text-align: left;
                }
            }

            &.communication-event {
                .circle-front {
                    background-color: #a2d4e8;
                }
            }

            &.note-event {
                .circle-front {
                    background-color: #f5da4d;
                }
            }

            &.created-event,
            &.assigned-event {
                .circle-front {
                    background-color: #abdac0;
                }
            }

            &.activity-event {
                .circle-front {
                    background-color: #ffb0b0;
                }
            }
            
            &.deleted-event { 
                .circle-front {
                    background-color: #ffb0b0;
                }
            }
        }
    }

    &.email-thread-list {
        padding-top: 12px;

        li {
            border-bottom: none;
            border-top: 1px solid rgba(0, 0, 0, 0.065);

            .list-item-body-component {
                display: block;
                padding: 0 0 12px 0;

                a {
                    display: block;
                    padding-top: 12px;

                    span {
                        color: #aaa;
                    }
                }
            }

            &:last-child {
                .list-item-body-component {
                    padding-bottom: 0;
                }
            }
        }
    }

    &.section-list {
        > li {
            > .list-item-body-component {
                padding: 16px 24px;

                .title-block {
                    font-size: 24px;
                }

                .content-block {
                    color: #373a3c;
                    font-size: 16px;
                }
            }
        }
    }

    &.settings-list {
        padding-left: 20px;
        padding-right: 0;

        li {
            &.composite {
                .list-item-main-component {
                    border-right: 1px solid rgba(0, 0, 0, 0.065);
                }
            }

            .list-item-body-component {
                padding-left: 0;
                padding-right: 8px;
                min-height: 62px;

                .list-item-main-component {
                    padding-right: 4px;

                    .title-block {
                        font-size: 16px;
                    }
                }

                .list-item-suffix-component {
                    padding-left: 12px;

                    .switch-field-component {
                        .form-field-input {
                            border: none;
                        }

                        .form-error {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @mixin select-boxes-list {
        > li {
            @media (min-width: $bp-tablet-portrait) {
                border-bottom: none;

                .content-block {
                    display: flex;
                    align-items: flex-end;
                    width: 100%;

                    .select-field-component {
                        margin-right: 24px;
                        width: 100px;

                        span {
                            font-size: 15px;
                        }

                        select {
                            cursor: pointer;
                        }
                    }
                }

                .list-item-body-component {
                    align-items: center !important;

                    .list-item-action-component {
                        margin-top: 16px;
                    }
                }
            }

            @media (min-width: $bp-gt-sm-desktop) {
                .content-block {
                    .select-field-component {
                        width: 140px;
                    }
                }
            }

            @media (min-width: $bp-gt-desktop) {
                .content-block {
                    .select-field-component {
                        width: 200px;
                    }
                }
            }

            .list-item-body-component {
                align-items: flex-start;
            }
        }
    }

    @mixin campaign-read-only-list {
        > li {
            margin-left: 16px;

            .list-item-body-component {
                justify-content: center;
                padding: 16px 16px 16px 0;

                .list-item-main-component {
                    .content-block {
                        font-size: 14px;
                        color: #222;
                    }
                }
            }
        }
    }

    &.campaign-read-only-list {
        @include campaign-read-only-list;
    }

    &.delivery-schedule-list {
        @include select-boxes-list;

        .list-item-action-component {
            margin-top: 28px;
        }

        .delivery-description {
            margin-right: 24px;
            color: #222;
            font-size: 15px;
            margin-bottom: 26px;
        }
    }

    &.auto-start-triggers-list {
        @include select-boxes-list;

        .list-item-action-component {
            margin-top: 10px;
        }
    }

    &.auto-response-rules-list {
        @include select-boxes-list;

         > li {
             &:nth-last-child(2),
             &.title-item {
                 border: none;
             }

             &.title-item {
                 .list-item-body-component {
                     padding-top: 12px;
                 }
             }

             .list-item-body-component {
                 min-height: 48px;

                 button {
                     padding: 0;
                 }

                 .list-item-main-component {
                     .title-block {
                         color: #f34e00;
                     }

                     .content-block {
                         @media (min-width: $bp-tablet-portrait) {
                             .form-component {
                                 display: flex;
                                 align-items: flex-end;
                                 width: 100%;

                                 .select-field-component {
                                     width: 260px;
                                 }
                             }
                         }

                         .select-field-component {
                             label {
                                 color: #aaa;
                             }
                         }
                     }
                 }
             }
         }

        &.default-list {
            > li {
                &.title-item {
                   border-top: 1px solid rgba(0, 0, 0, 0.125);
                }

                .list-item-body-component {
                    background-color: #eceeef;

                    .content-block {
                        .select-field-component {
                            &.muted {
                                .form-field-input {
                                    color: #aaa;
                                }

                                select {
                                    cursor: default;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact-list-header {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);

    .contacts-count {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        font-size: 13px;
        color: #aaa;
    }

    .clear-filters-button {
        button {
            font-size: 16px;
        }
    }
}


