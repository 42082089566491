

/*
    Colors
*/
$checked-color: #26a654; // green
//$checked-color: #009688; // teal

$green: #008F51; // a different green for animated check component
$black: #373a3c;
$dark-gray: #494c4e;
$medium-gray: #7f7f7f;
$dark-red: #d70206;
$gray: #aaa;
$light-gray: #d2d2d2;
$red-orange: #f34e00;

$off-black: #292b2c;

// button colors
$bg-color-button-hover: rgba(0,0,0,0.05);
$bg-color-button-active: rgba(0,0,0,0.05);//#dddddd;//#006fdc;
$text-color-button-active: inherit;


$bg-color-button-hover-dark: rgba(0,0,0,0.18);
$bg-color-button-active-dark: rgba(0,0,0,0.18);


// toolbar colors
$bg-color-toolbar-button-hover: $bg-color-button-hover;
$bg-color-toolbar-button-active: $bg-color-button-active;
$text-color-toolbar-button-active: $text-color-button-active;

// TODO: implement the above variables for generic button and toolbar styles...

// specific toolbars
$bg-color-app-toolbar-button-hover: $bg-color-toolbar-button-hover;
$bg-color-app-toolbar-button-active: $bg-color-toolbar-button-active;
$text-color-app-toolbar-button-active: $text-color-toolbar-button-active;



// side panel colors
$bg-color-dark: #333C44;

// nav
$color-nav-item: rgba(0, 0, 0, 0.54);
$color-nav-item-hover: rgba(0, 0, 0, 0.87);
$color-nav-item-active: #e44c00;

$color-nav-item-dark: #a1a1a1;
$color-nav-item-hover-dark: #fff;
$color-nav-item-active-dark: #fff;


//$color-touch-indicator: rgba(182, 0, 121, 0.5);
$color-touch-indicator: #d60091;






// rgba(#000, .035) // #eee

// $image_path:            "../assets/images/" !default;
// $image_pathdemo:        "../assets/images-demo/" !default;


$skin:                  $brand-primary;
$skin-light:            #42A5F5 !default; // Blue 400
$dark:                  #333C44 !default; // darken Blue 100 > #343E46
$dark-light:            lighten($dark, 5%) !default;
$bright:                #fafafa !default;
$white:                 #fff !default;
$gray-light2:           #999 !default;

$page-bg:               #f5f5f5 !default; // background color of .app-page-container


// $state-default-bg:              rgba(0,0,0, .1) !default; // also: pagination bg
// $state-default-border:          darken(adjust-hue($state-default-bg, -10), 5%) !default;
// $state-default-border-light:    #f3f3f3 !default;
//
// //== Typography
// $font-weight-thin:      300 !default;
// $font-family-condensed: "Roboto Condensed", "Roboto", "Helvetica Neue", Arial, sans-serif !default
//
//
// // Cards
// $card-padding:          20px !default;
// $border-radius-card:    2px !default;
//
// // Grid Variables
// $element-top-margin:        ($grid-gutter-width-base/3) !default;
// $element-bottom-margin:     ($grid-gutter-width-base*2)/3 !default;
//
// // Tables
// $theme-dark-data-table-header-color: rgba(#fff, 0.54);


// = Default dark background side_panel =
$side_panel_bg_color:                    $dark;
$side_panel_text_color:                  #a1a1a1;
$nav_link_hover_bg:                   transparent;
$nav_link_hover_color:                #fafafa;

// the reason .active bg is different from .open bg is becasue there's no border between li
// if one li is .active, and sibling is .open, then they have same bg, which is confusing
$nav_link_active_bg:                  transparent;
$nav_link_open_bg:                    rgba(0,0,0,.1);
$nav_link_active_color:               $nav_link_hover_color;

$nav_sub_bg:                          $nav_link_open_bg;
$nav_sub_link_active_color:           $nav_link_hover_color;
$nav_sub2_bg:                         rgba(0,0,0,.1);
$nav_sub3_bg:                         rgba(0,0,0,.1);





// Light background side_panel
// --------------------------------------------------
$light_nav_text_color:                $body-color;
$light_nav_link_hover_bg:             transparent; //#f6f6f6;
$light_nav_link_hover_color:          $brand-primary;
$light_nav_link_active_bg:            transparent;
$light_nav_link_active_color:         $brand-primary;
$light_nav_link_open_bg:              rgba(0,0,0,.05);      // vertical nav only
$light_nav_link_open_color:           $light_nav_link_active_color;   // vertical nav only

$light_nav_sub_nav_bg:                $light_nav_link_open_bg;
$light_nav_sub_link_active_color:     $brand-primary;
// $light_nav_sub2_nav_bg:               #eaeaea;
$light_nav_sub2_nav_bg:               rgba(0,0,0,.05);
// $light_nav_sub3_nav_bg:               #ddd;
$light_nav_sub3_nav_bg:               rgba(0,0,0,.05);



// Themes
// --------------------------------------------------
$theme_dark_text_color:             rgba(255,255,255,.7);
$theme_dark_text_color_light:       rgba(255,255,255,.25);

$theme_dark_side_panel_bg_color:    $dark;
$theme_dark_header_bg_color:        lighten($dark, 6%);
$theme_dark_page_bg:                lighten($dark, 2.5%);
$theme_dark_box_bg:                 $theme_dark_header_bg_color;

$theme_gray_side_panel_bg_color:    grayscale($theme_dark_side_panel_bg_color); // or 3c3c3c
$theme_gray_header_bg_color:        grayscale($theme_dark_header_bg_color); // or #4c4c4c
$theme_gray_page_bg:                grayscale($theme_dark_page_bg); // #444;
$theme_gray_text_color:             $theme_dark_text_color;
$theme_gray_box_bg:                 $theme_gray_header_bg_color;



$turboleads-gradient-red:           linear-gradient(to bottom, #e44c00, #d41d00);
$ihouse-gradient-blue:              linear-gradient(to bottom, #007cf1, #005dbd);
