
.test-list {
    padding-top: 12px;
}
.test-list-item {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 16px 24px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
}
.test-list-item:last-child {
    border-bottom: 0;
}
.test-list-item .button {
    margin-top: 8px;
}
