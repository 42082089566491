

.ih-notification-layer {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 9012; /* calculate this? */

    width: 280px;

    box-sizing: border-box;

    font-family: Helevetica, Arial, sans-serif;
    font-size: 16px;
    color: #666;
    line-height: 1.25;
    text-align: center;

    @media all and (min-width: $bp-tablet-landscape) {
        width: 360px;
    }
}
.ih-notification-layer-top-center,
.ih-notification-layer-bottom-center {
    left: 50%;
    margin-left: -140px;

    @media all and (min-width: $bp-tablet-landscape) {
        margin-left: -180px;
    }
}
.ih-notification-layer-top-right,
.ih-notification-layer-bottom-right {
    right: 20px;
    left: auto;
}
[class*="ih-notification-layer-bottom-"] {
    top: auto;
    bottom: 20px;
}

.ih-notification {
    position: relative;
//    display: inline-block;
    display: block;
    width: 100%;
    max-height: 200px;
    padding: 24px;
    box-sizing: border-box;
    margin: -100px auto 80px auto;
    overflow-y: auto;

    background-color: rgba(40, 40, 40, 0.95);
    box-shadow: none;
    border-radius: 32px;
    color: #fff;
    text-align: left;

    opacity: 0;
    transition: 0.3s all ease-in-out;
}
.ih-notification.open {
    opacity: 1;
}
[class*="ih-notification-layer-top-"] .ih-notification.open {
    margin-top: 0px;
    margin-bottom: 10px;
}
[class*="ih-notification-layer-bottom-"] .ih-notification.open {
    margin: 10px auto 0px auto;
}


.ih-notification.closing {
    max-height: 64px;
    overflow: hidden;
}
[class*="ih-notification-layer-top-"] .ih-notification.closing {
    margin: -100px auto 36px auto;
}
[class*="ih-notification-layer-bottom-"] .ih-notification.closing {
    margin: 36px auto -100px auto;
}


.ih-notification-close-x {
    position: absolute;
    top: 20px;
    right: 20px;

    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    opacity: 1;

    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    color: #666;
    text-align: center;
    line-height: 24px;

    cursor: pointer;
    transition: 0.3s all ease-in-out;
}
.ih-notification-close-x:hover {
    opacity: 1 !important;
    color: #ffffff !important;
}
.ih-notification.has-close-x .ih-notification-message {
    padding-right: 24px;
}


/* icons */
.ih-notification.has-icon .ih-notification-message {
    padding-left: 34px;
}
.ih-notification.has-icon [class^="ih-icon-"] {
    position: absolute;
    top: -4px;
    left: -2px;
    font-size: 28px;
}
.ih-notification-spinner-icon {
    position: absolute;
    top: -4px;
    left: -2px;
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    border-left-color: rgba(255,255,255,0.5);
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

/* statuses/colors */
.ih-notification-success {
    background-color: rgba(29, 115, 29, 0.95);
    color: #fff;
}
.ih-notification-success [class^="ih-icon-"],
.ih-notification-success .ih-notification-close-x {
    color: #4AB54A;
}

.ih-notification-reminder {
    background-color: rgba(8, 130, 177, 0.95);
    color: #fff;
}
.ih-notification-reminder [class^="ih-icon-"],
.ih-notification-reminder .ih-notification-close-x {
    color: #71D0F5;
}
.ih-notification-reminder .ih-notification-close-x {
    opacity: 0.7;
}

// this is the spinner
.ih-notification-waiting {
//    background-color: rgba(60, 150, 60, 0.85);
    color: #fff;
}


.ih-notification-warning {
//    background-color: rgba(212, 137, 0, 0.95);
    color: #fff;
}
.ih-notification-warning [class^="ih-icon-"] {
//.ih-notification-warning .ih-notification-close-x {
    color: #FFB510;
}

.ih-notification-error {
    background-color: rgba(173, 13, 13, 0.95);
    color: #fff;
}
.ih-notification-error [class^="ih-icon-"],
.ih-notification-error .ih-notification-close-x {
    color: #F74F43;
}

/*New Stuff*/

.notification {
    font-family: Helevetica, Arial, sans-serif;
    font-size: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 350px;
    margin: auto;
    z-index: 99999;

    .notification-container {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 10px;
        position: relative;
        transition: 300ms ease-in-out;
        width: 100%;
        z-index: 9020;
        opacity: 0.85;

        .notification-content {
            background-clip: padding-box;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
            outline: 0;
            padding-top: 20px;
            position: relative;

            .notification-header {
                h4 {
                    color: #fff;
                    font-family: 'Roboto', Arial, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    margin: 0;
                    padding: 0 36px 8px 24px;
                }

                button {
                    background-color: transparent;
                    border: 0;
                    color: #fff !important;
                    font-size: 20px;
                    font-weight: bold;
                    height: 24px;
                    line-height: 24px;
                    margin: 0;
                    min-width: unset;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    text-shadow: none;
                    top: 10px;
                    width: 24px;

                    &:hover {
                        color: #666 !important;
                    }
                }
            }

            .notification-body {
                color: #fff;
                padding: 4px 16px 16px 16px;
            }
        }
    }

    &.open-enter {
        opacity: 0.01;

        &.open-enter-active {
            opacity: 1;
        }
    }

    &.open-exit {
        opacity: 1;

        &.open-exit-active {
            opacity: 0.01;
        }
    }

    /*======================*/
    /*= NOTIFICATION TYPES =*/
    /*======================*/

    &.type-success {
        .notification-content {
            background-color: rgba(29, 115, 29, 0.95);
        }
    }

    &.type-error {
        .notification-content {
            background-color: rgba(173, 13, 13, 0.95);
        }
    }

    &.type-reminder {
        .notification-content {
            background-color: rgba(8, 130, 177, 0.95);
        }
    }

    &.type-warning {
        .notification-content {
            background-color: #FFB510;
        }
    }

    /*=======================*/
    /*= NOTIFICATION THEMES =*/
    /*=======================*/

    @mixin notification-theme-default {
        &.open-enter {
            .notification-container {
                transform: translate(0, -25%);
            }

            &.open-enter-active {
                .notification-container {
                    transform: translate(0, 0);
                }
            }
        }

        &.open-exit {
            .notification-container {
                transform: translate(0, 0);
            }

            &.open-exit-active {
                .notification-container {
                    transform: translate(0, -25%);
                }
            }
        }
    }

    &.theme-default {
        @include notification-theme-default;
    }

    @mixin notification-theme-bottom-sheet {
        max-width: 650px !important;
        bottom: -12px !important;
        top: auto !important;

        .notification-container {
            .notification-content {
                border-radius: 0;
                padding-bottom: 12px;

                .notification-body {
                    font-size: 14px;
                }
            }
        }

        &.open-enter {
            .notification-container {
                transform: translate(0, 25%);
            }

            &.open-enter-active {
                .notification-container {
                    transform: translate(0, 0);
                }
            }
        }

        &.open-exit {
            .notification-container {
                transform: translate(0, 0);
            }

            &.open-exit-active {
                .notification-container {
                    transform: translate(0, 25%);
                }
            }
        }
    }

    &.theme-bottom-sheet {
        @include notification-theme-bottom-sheet;

        &.red-bottom-sheet {
            .notification-container {
                opacity: 1 !important;

                .notification-content {
                    background-color: #4C95E8 !important;

                    .notification-header {
                        h4 {
                            padding: 4px 8px 8px 16px;
                        }
                    }
                }
            }
        }
    }
}


