

.contact-task-list-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: auto;
    min-height: 72px;

    background-color: #fff;
    opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);


    .icon-checkbox-checked {
        display: none;
    }


    & > * {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .list-item-main {
        font-size: $list-item-font-size;
    }
    &:last-child {
        border-bottom: 0;
    }
    .task-description {
        line-height: 1.25;
        display: inline-block;
    }
    .list-item-main-content {
        cursor: pointer;
    }

    .mark-as-incomplete {
        display: none;
    }

    /* overdue tasks */
    &.overdue .list-item-main-content .small:before {
        content: '!';
        position: relative;
        top: -1px;
        display: inline-block;
        color: #fff;
        background-color: #d70206;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        padding: 2px;
        box-sizing: content-box;
        line-height: 8px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        margin-right: 2px;
        font-family: 'Times New Roman';
    }



    /* completed state */
    &.completed {
        .list-item-main-content .small:before {
            background-color: #d2d2d2 !important;
        }
        .icon-checkbox {
            display: none;
        }
        .icon-checkbox-checked {
            display: block;
        }
        .list-item-action {
            color: #d2d2d2 !important;
        }
        .mark-as-complete {
            display: none;
        }
        .mark-as-incomplete {
            display: block;
        }

        .task-description,
        &.overdue {
            color: #aaa;
            text-decoration: line-through;
        }
        &.overdue {
            .task-description:before {
                display: none;
            }
        }
    }

}
