/*===========*/
/*= BUTTONS =*/
/*===========*/

.btn {
    &.btn-tab {
        color: $off-black !important;
    }
}

/*========*/
/*= CARD =*/
/*========*/

.card {
    .card-content {
        h2 {
            border-bottom: 1px solid #ddd;
        }
    }
}

/*==========*/
/*= CIRCLE =*/
/*==========*/

.circle {
    .circle-front {
        background-color: #b3b3b3;
    }
}

/*=========*/
/*= CHART =*/
/*=========*/

.chart-component {
    ul {
        li {
            .ct-key-value,
            .ct-key-percent {
                color: $off-black !important;
            }
        }
    }
}

/*===============*/
/*= CONTROL BAR =*/
/*===============*/

.control-bar-component {
    color: $off-black !important;
}

/*========*/
/*= FORM =*/
/*========*/

.form-field-component {
    > .form-field-input {
        color: $off-black !important;
    }

    label {
        color: $medium-gray;
    }
}

.form-field-component {
    &.multi-select-table-field-component {
        .table {
            > li {
                .icon-checkbox {
                    color: $medium-gray;
                }
            }
        }
    }

    &.checkbox-field-component {
        .form-field-input {
            .box {
                border: 2px solid #B3B3B3;
            }
        }
    }
}

/*=============*/
/*= HINT ICON =*/
/*=============*/

.hint-icon {
    color: $medium-gray;
}

/*=========*/
/*= LISTS =*/
/*=========*/

.list-component {
    > li {
        border-bottom: 1px solid #ddd;

        .list-item-body-component {
            .list-item-main-component {
                .content-block {
                    color: $off-black !important;
                }
            }
        }
    }

    &.getting-started-list {
        > li {
            &.completed {
                .title-block {
                    color: $off-black !important;
                }
            }
        }
    }

    &.desktop-contact-list {
        > li {
            &.list-header {
                border-bottom: 1px solid #ddd !important;
            }

            .list-item-body-component {
                .list-item-main-component {
                    .content-block {
                        color: $medium-gray !important;
                    }
                }
            }
        }
    }

    &.settings-list {
        > li {
            &.composite {
                .list-item-body-component {
                    .list-item-main-component {
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }
    }

    &.task-list {
        > li {
            .list-item-body-component {
                .icon-checkbox-checked {
                    color: $gray !important;
                }
            }

            &.completed {
                .title-block {
                    color: $off-black !important;
                }
            }
        }
    }

    &.timeline-list {
        > li {
            .list-item-footer-component {
                color: $off-black !important;
            }
        }
    }

    &.email-thread-list {
        li {
            .list-item-body-component {
                a {
                    span {
                        color: $off-black !important;
                    }
                }
            }
        }
    }

    &.auto-response-rules-list {
        > li {
            .list-item-body-component {
                .list-item-main-component {
                    .content-block {
                        .select-field-component {
                            label {
                                color: $off-black !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.default-list {
        > li {
            .list-item-body-component {
                .content-block {
                    .select-field-component {
                        &.muted {
                            .form-field-input {
                                color: $off-black !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact-list-header {
    border-bottom: 1px solid #ddd;

    .contacts-count {
        color: $off-black !important;
    }
}

/*========*/
/*= MENU =*/
/*========*/

.menu-group-label {
    color: $medium-gray !important;
}

.menu-item-checkbox {
    border: 2px solid $medium-gray !important;
}

/*==========*/
/*= MIXINS =*/
/*==========*/

.placeholder {
    color: $medium-gray !important;
}

/*==============*/
/*= SIDE PANEL =*/
/*==============*/

.side-panel-filter {
    label {
        color: #48aeff !important;
    }
}


/*========*/
/*= TABS =*/
/*========*/

.page-header-tabs {
    .tabs-component {
        ul {
            li {
                color: $medium-gray !important;
            }
        }
    }
}



