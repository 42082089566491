.login-view {
    @media screen and (max-width: 992px) {
        background: linear-gradient(to bottom, #e44c00, #d41d00);
    }

    background: linear-gradient(to bottom, #007cf1, #005dbd);

    .splash-page {
        justify-content: flex-start;
        padding: 16px;
    }

    header {
        padding: 60px 0 0 0;

        h1 {
            color: #fff;
            text-align: center;
        }
    }

    #login-form {
        width: 100%;
        max-width: 400px;
        padding: 16px;

        border-radius: 2px;
        background-color: #fff;

        @include z-depth-2;
    }
}

// TODO: abstract this out into more generic forms/inputs file
.password-text-visibility-toggle {
    position: absolute;
    top: 28px;
    right: 0;
    @include icon-wrapper;
}
.password-text-visibility-toggle .icon {
    display: block;
}


/*
 * Reset Password
 */
.reset-password-form {
    padding: 0 16px;
}

/*New Stuff*/

@mixin auth-page-no-nav {
    @media screen and (max-width: 992px) {
        background: linear-gradient(to bottom, #e44c00, #d41d00);
    }

    background: linear-gradient(to bottom, #007cf1, #005dbd);
    display: flex;
    justify-content: center;
    padding: 76px 16px 16px 16px;

    header {
        text-align: center;
    }

    form {
        @include z-depth-2;

        background-color: #fff;
        border-radius: 2px;
        max-width: 400px;
        padding: 16px;
        width: 100%;

        button {
            margin-top: 8px;
        }
    }
}

.login-page {
    @include auth-page-no-nav;
}

@media only screen and (min-width: $l_screen_md_min) {
    .auth-page {
        @include auth-page-no-nav;
    }
}

@media only screen and (max-width: $l_screen_md_min) {
    .auth-page {
        .page-content {
            padding: 16px 24px;
        }
    }
}

