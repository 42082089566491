



.follow-up-list {
    padding: 0 16px;
}

.follow-up-list-item {
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    height: auto;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    background-color: #fff;
    opacity: 1;
}
.follow-up-list-item:last-child {
    border-bottom: 0;
}

.follow-up-list-item .list-item-main {
    display: block;
    flex: 1 1 auto;
    padding: 12px 0;

    color: inherit !important;
    text-decoration: none !important;
}

.follow-up-list-item.with-switch .switch {
    flex: none;
}

.follow-up-list-item .list-item-collapsed {
    display: none;
    flex: 1 1 100%;
}
.follow-up-list-item.expanded .list-item-collapsed {
    display: block;
}

.reason-collapsed {
    padding: 0 16px 22px 16px;
}


.follow-up-view .follow-up-fieldset .fieldset-pre {
    width: auto;
    padding-right: 0;
}
.follow-up-view .follow-up-fieldset .fieldset-pre .fieldset-icon {
    margin-left: -16px;
}
.follow-up-fieldset .fieldset-action {
    display: none;
}

.follow-up-fieldset .fieldset-fields {
    padding-right: 16px;
}
