/*
 * Icons
 *
 * material design icons
 * optimized for 24px
 * example:
 * <svg class="icon icon-menu"><use xlink:href="{{ versioned('materialdesign.svg') }}#icon-menu"></use></svg>
 *
 *
 * use color property to style based on context, e.g.
 * .navbar .icon {
 *     color: red;
 * }
 *
 */
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    font-size: 24px;
}

// don't show svg titles
svg.icon {
    pointer-events: none;
}



// dark icon for display on a light background
// .icon.md-dark { color: rgba(0, 0, 0, 0.54); }
// .icon.md-dark.md-disabled { color: rgba(0, 0, 0, 0.26); }
//
// // light icon for display on a dark background
// .icon.md-light { color: rgba(255, 255, 255, 1); }
// .icon.md-light.md-disabled { color: rgba(255, 255, 255, 0.3); }


.icon-wrapper {
    @include icon-wrapper;
}



.checkbox-icon-component {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transition: .3s opacity ease-in-out;
    }
    .icon-checkbox {
       color: #707070;
       opacity: 1;
    }

    .icon-checkbox-checked {
        color: #707070;
        opacity: 0;
    }

    &.disabled {
        cursor: not-allowed;
        color: #999 !important;
        opacity: 0.5 !important;
    }

    &.checked {
        .icon-checkbox {
            opacity: 0;
        }
        .icon-checkbox-checked {
            opacity: 1;
        }
    }
}
