
// TODO: needs cleanup

.settings-view .page {
    margin-bottom: 2*$units;
}

.settings-list {
    padding: 0 16px;
}

.settings-list-item {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    background-color: #fff;
    opacity: 1;
}
.settings-list-item:last-child {
    border-bottom: 0;
}
.settings-list-item.with-switch {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.settings-list-item .list-item-main {
    display: block;
    flex: 1 1 auto;
    padding: 12px 0;
    color: inherit !important;
    text-decoration: none !important;
}

.settings-list-item.with-switch .switch {
    flex: none;
}
.settings-list-item .help-icon {
    right: -16px;
}


.notifications-warning {
    margin: 2px 16px 0 16px;
    padding: 8px 0 0 0;
    border-top: 1px solid #dd2c00;
}
/* allow long email addresses to break in the middle without hyphenation if needed */
.lead-capture-email {
    word-break: break-all;
}



/*
 * overdue reminders page
 */
.interval-fieldset {
    padding-bottom: 8px;
}
.interval-fieldset label {
    display: block;
    margin: 0;
    padding-top: 14px;
    font-size: 14px;
    color: #aaa;
}
.interval-fieldset .text-field {
    padding-right: 8px;
    width: 1*$units;
}



/*
 * reasons, message templates (email and text message templates)
 */
.manage-list {
    padding: 16px;
}

.manage-list-item {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    background-color: #fff;
    opacity: 1;

    @include columns;
    align-items: center;
}
.manage-list-item:last-child {
    border-bottom: 0;
}

.manage-list-item .list-item-main {
    display: block;
    flex: 1 1 auto;
    padding: 12px 0;

    color: inherit !important;
    text-decoration: none !important;
}
.manage-list-item .list-item-action {
    flex: none;
    margin: 0;
    color: #ccc !important;
}

.reason-form {
    padding: 16px;
}
.message-template-form {
    padding: 0;
}


/* New */

.settings-warning {
    border-top: 1px solid #dd2c00;
    color: #dd2c00;
    font-size: 12px;
    margin-left: 20px;
    padding: 8px 0;
}

.setting-page {
    .control-bar-title {
        color: #fff;
        font-size: 16px;
        padding-left: 56px;
    }

    .page-content {
        padding: 8px 16px 8px 32px;
    }

    .setting-description {
        padding: 8px 0 16px 0;
    }
}