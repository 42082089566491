.table {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        transition: 0.3s ease-in-out;
        padding: 0 20px;
        height: 52px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);
        display: flex;
        align-items: center;

        &:last-child {
            border-bottom: none !important;
        }

        &.table-row {
            cursor: pointer;
        }

        &.header-row {
            color: #aaa;
            height: 42px;
            font-size: 13px;
        }

        &.body-row {
            font-size: 15px;

            &:hover {
                background-color: #eeeeee;
            }
        }

        .checkbox-icon-component {
            margin-right: 16px;
        }

        .table-header,
        .table-data {
            display: flex;
            align-items: center;

            &.sm-col {
                flex-basis: 10%;
            }

            &.md-col {
                flex-basis: 15%;
            }

            &.lg-col {
                flex-basis: 25%;
            }
        }

        .table-data {
            cursor: pointer;
            height: 100%;
        }
    }
}