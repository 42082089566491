// note: no img assets required for layout

html {
    height: 100%;
    background-color: $l_body_bg; // important! otherwise in "layout-boxed" mode, content > height 100% will
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    // background-color: $l_body_bg;
}

html,
body {
    font-size: 14px;
}


.full-height {
    height: 100% !important;
}

.app-container,
#app-container,
#app-inner,
.main-app-container,
.page-container {
    height: 100%;
}

.container-fluid
 {
    padding-right: 0;
    padding-left: 0;


    @media only screen and (min-width: $bp-sm-tablet-portrait) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media only screen and (min-width: $l_screen_md_min) {
        padding-left: 24px;
        padding-right: 24px;
    }

    &.padding-lg-h {
        padding-left: $grid-gutter-width-base;
        padding-right: $grid-gutter-width-base;
    }

    &.with-maxwidth {
        max-width: $container_fluid_maxwidth;
    }

    &.with-maxwidth-lg {
        max-width: map-get($container-max-widths, lg);
    }

    &.with-maxwidth-md {
        max-width: map-get($container-max-widths, lg);
    }

    &.with-maxwidth-sm {
        max-width: map-get($container-max-widths, md);
    }

    &.no-breadcrumbs {
        padding-top: 0;
        padding-bottom: 1em;
    }

    &.top-padding {
        padding-top: 1em;
    }

    &.side-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

    &.dt-top-padding {
        @media only screen and (min-width: $l_screen_md_min) {
            padding-top: 1em;
        }
    }

    &.mb-top-padding {
        @media only screen and (max-width: $l_screen_md_min) {
            padding-top: 1em;
        }
    }

    &.mb-side-padding {
        @media only screen and (max-width: 600px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &.no-side-padding {
        padding: 0 !important;
        .row {
            margin: 0 !important;
             > div {
                padding: 0 !important;
            }
        }
    }

    &.no-bottom-padding {
        padding: 0 !important;
        .row {
            margin: 0 !important;
            > div {
              padding: 0 !important;
            }
        }
    }
}


/*
    Page container
*/
.app-page-container {
    width: 100%;
    height: 100%;

    .app-content-wrapper {
        &.white-background {
            background-color: $l-white !important;
        }
        min-height: 100%;
        position: relative;
        background-color: $l_white; // important! otherwise trasparent background-color won't cover the side-panel, when scroll down

        .app-content {
            // z-index: 10;
            padding-bottom: $side_panel_footer_height;
            min-height: 100%;
            transition: all 0.3s ease;

            &.full-width {
                width: 100%;
            }
        }
    }
}

.content-white-background {
    .app-page-container {
        .app-content-wrapper {
            background-color: $l-white !important;
        }
    }
}


// for fixed-header
.fixed-header {
    .app-page-container {
        .app-content-wrapper {
            .app-content {
                padding-top: $l_header_height;
            }
        }
    }
}
// for md-sidenav-right
.app-page-container {
    &.scroll-disabled {
        overflow: hidden;
    }
}
// mobile
@media only screen and (max-width: $l_screen_sm_max) {
    .app-page-container {
        position: relative;
        // z-index: $l_zindex_page_container_mobile;
        padding-left: 0;
        transition: transform 0.25s ease;
        background-color: $l_white; // important! otherwise trasparent background-color won't cover the side panel

        .app-content-wrapper {
            .app-content {
                overflow-x: hidden;
                // height: auto; // minimizes address bar on scroll
            }
        }
    }
    // off-canvas side panel
    .side-panel-mobile-open {
        .app-page-container {
            overflow: hidden;
            position: fixed;
            // transform: translateX($l_side_panel_width);
        }
    }
}
// desktop
@media only screen and (min-width: $l_screen_md_min) {
    .app-page-container {
        .app-content-wrapper {
            .app-content {
                padding-left: $l_side_panel_width;
            }

            .app-footer {
                left: $l_side_panel_width;
            }
        }
    }

    .nav-collapsed {
        .app-page-container {
            .app-content-wrapper {
                .app-content {
                    padding-left: $l_side_panel_width_collapsed;
                }

                .app-footer {
                    left: $l_side_panel_width_collapsed;
                }
            }
        }
    }
}
